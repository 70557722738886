import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import * as moment from 'moment';

import { CodeValueGroups, CommandResponse, PhxConstants, StateAction } from '../../common/model';
import { ApiService, CodeValueService, PhxLocalizationService, WorkflowService } from '../../common';
import {
  DocHeaderOrgInfo,
  DocHeaderUserProfileInfo,
  DocHeaderWorkOrderInfo,
  IApplicableComplianceTemplate,
  IApplicableComplianceTemplateDto,
  IComplianceDocument,
  IComplianceDocumentDto,
  IComplianceDocumentEntityGroup,
  IComplianceDocumentHeader,
  IComplianceDocumentLiteDto
} from './compliance-document.model';
import { catchError, map, tap } from 'rxjs/operators';
import { PhxSideBarService } from 'src/app/common/services/phx-side-bar.service';
import { environment } from '../../../environments/environment';
import { DocumentComplianceData } from 'src/app/common/data-services/fbo-monolith-compliance-data/models';
import { GlobalConfiguration } from 'src/app/common/model/gobal-configuration/global-configuration';
import { ClientExclusion } from './client-exclusion';
import { cleanComplianceRulesResult } from './utilities/clean-compliance-rules-result/clean-compliance-rules-result.util';

interface IComplianceDocumentStateItem {
  entityTypeId: number;
  entityId: number;
  complianceDocuments: IComplianceDocumentDto[];
}

@Injectable()
export class ComplianceDocumentService {

  static isLoadingComplianceDocumentList: {
    [entityTypeIdAndEntityId: string]: boolean;
  } = {};

  private currentEntityTypeId: number;
  private currentEntityId: number;
  private complianceDocumentsState: IComplianceDocumentStateItem[] = [];

  private complianceDocumentsSubject: BehaviorSubject<IComplianceDocumentDto[]> = new BehaviorSubject(null);
  complianceDocuments$: Observable<IComplianceDocumentDto[]> = this.complianceDocumentsSubject.asObservable();

  private excudedDocumentComplianceDataClientList: number[] | null = null;

  private get complianceDocuments(): IComplianceDocumentDto[] {
    return this.complianceDocumentsSubject.value;
  }

  private set complianceDocuments(data: IComplianceDocumentDto[]) {
    this.complianceDocumentsSubject.next(data);
  }

  constructor(
    private apiService: ApiService,
    private workflowService: WorkflowService,
    private codeValueService: CodeValueService,
    private phxSidebarService: PhxSideBarService,
    private phxLocalizationService: PhxLocalizationService
  ) {

    this.complianceDocuments$.subscribe((data: IComplianceDocumentDto[]) => {
      if (this.currentEntityId && this.currentEntityTypeId) {
        const foundStateItemIndex = this.complianceDocumentsState.findIndex(item => item.entityId === this.currentEntityId && item.entityTypeId === this.currentEntityTypeId);
        if (foundStateItemIndex > -1) {
          if (data) {
            this.complianceDocumentsState[foundStateItemIndex].complianceDocuments = data;
          } else {
            this.complianceDocumentsState.splice(foundStateItemIndex, 1);
          }
        } else {
          this.complianceDocumentsState.push({
            entityId: this.currentEntityId,
            entityTypeId: this.currentEntityTypeId,
            complianceDocuments: data
          });
        }
      }
    });
  }

  /** NOTE: if page sidebar is listening for a refresh request - it will update compliancy checklist */
  triggerComplianceDocumentActionExecuted() {
    this.phxSidebarService.onRefresh.emit();
  }

  loadList(entityTypeId: number, entityId: number, forceGet = false, oDataParams = null) {
    this.currentEntityId = entityId;
    this.currentEntityTypeId = entityTypeId;
    const foundStateItem = this.complianceDocumentsState.find(item => item.entityId === this.currentEntityId && item.entityTypeId === this.currentEntityTypeId);
    if ((!foundStateItem && entityTypeId > 0 && entityId > 0 && !this.getIsLoadingComplianceDocumentList(entityTypeId, entityId)) || forceGet) {
      this.setIsLoadingComplianceDocumentList(entityTypeId, entityId, true);
      this.apiService.query(`ComplianceDocument/getListComplianceDocumentsByEntityTypeIdAndEntityId/${entityTypeId}/${entityId}${this.param(oDataParams)}`)
        .subscribe({
          next: (response: any) => {
              const lastRequestedDatetime = new Date();
              this.complianceDocuments = response.Items.map((doc: IComplianceDocumentDto) => ({ ...doc, lastRequestedDatetime }));
              this.setIsLoadingComplianceDocumentList(entityTypeId, entityId, false);
            },
          error: () => {
            this.setIsLoadingComplianceDocumentList(entityTypeId, entityId, false);
          }
        });
    } else {
      this.complianceDocuments = foundStateItem.complianceDocuments;
    }
  }

  groupHeadersByEntity(headers: IComplianceDocumentHeader[]) {
    let result = headers.reduce<IComplianceDocumentEntityGroup[]>((entityGroups, header) => {
      let group = entityGroups.find(x => x.EntityTypeId === header.EntityTypeId && x.EntityId === header.EntityId);
      if (group == null) {
        group = {
          EntityTypeId: header.EntityTypeId,
          EntityId: header.EntityId,
          Label: header.Label,
          Headers: []
        };
        entityGroups.push(group);
      }
      group.Headers.push(header);
      return entityGroups;
    }, []);

    // Put WOV documents in WO group
    const wovEntityGroups = result.filter(x => x.EntityTypeId === PhxConstants.EntityType.WorkOrderVersion);
    if (wovEntityGroups.length) {
      const woGroup: IComplianceDocumentEntityGroup =
        result.find(x => x.EntityTypeId === PhxConstants.EntityType.WorkOrder)
        || { EntityTypeId: PhxConstants.EntityType.WorkOrder, EntityId: -1, Headers: [] };

      wovEntityGroups.forEach(group => {
        woGroup.Headers.push(...group.Headers);
      });

      result = result.filter(group => !wovEntityGroups.includes(group));
    }

    result.forEach(group => group.Headers.sort((a, b) => this.getSortIndexComplianceDocumentHeader(a, b)));

    result.sort((a, b) => this.getSortIndexEntityType(a.EntityTypeId, b.EntityTypeId));
    return result;
  }

  getAvailableStateActions(entityId: number) {
    return this.workflowService.getAvailableStateActions(PhxConstants.EntityType.ComplianceDocument, entityId, false);
  }

  getHeadersFromEntityGroups(entityGroups: IComplianceDocumentEntityGroup[]): IComplianceDocumentHeader[] {
    return entityGroups.reduce<IComplianceDocumentHeader[]>((headers, group) => {
      return headers.concat(group.Headers);
    }, []);
  }

  getComplianceDocumentHeaders(documents: IComplianceDocumentDto[]): IComplianceDocumentHeader[] {
    return documents.reduce<IComplianceDocumentHeader[]>((headers: IComplianceDocumentHeader[], document: IComplianceDocumentDto) => {
      let header = headers.find(x => x.Id === document.ComplianceDocumentHeaderId);
      if (header == null) {
        const templates = this.mapComplianceTemplates(document.ApplicableComplianceTemplates);
        header = {
          Id: document.ComplianceDocumentHeaderId,
          EntityTypeId: document.ComplianceDocumentHeaderToEntityTypeId,
          EntityId: document.ComplianceDocumentHeaderToEntityId,
          LastRequestedDatetime: document.lastRequestedDatetime,
          Label: document.ComplianceDocumentHeaderLabel,

          ComplianceDocumentRuleId: document.ComplianceDocumentRuleId,
          ComplianceDocumentRuleAreaTypeId: document.ComplianceDocumentRuleAreaTypeId,
          ComplianceDocumentRuleRequiredTypeId: document.ComplianceDocumentRuleRequiredTypeId,
          ComplianceDocumentRuleDisplayName: document.ComplianceDocumentRuleDisplayName,
          ComplianceDocumentRuleExpiryTypeId: document.ComplianceDocumentRuleExpiryTypeId,
          ComplianceDocumentCurrent: null,
          PreviousDocuments: [],
          ApplicableTemplateDocuments: templates,
          HasTemplates: templates.some(x => x.TemplateType === PhxConstants.ComplianceTemplateDocumentType.Template),
          HasSamples: templates.some(x => x.TemplateType === PhxConstants.ComplianceTemplateDocumentType.Sample),
          HasRulesetDefined: document.HasRulesetDefined,
          ComplianceDocumentTypeIds: document.ComplianceDocumentTypeIds
        };
        headers.push(header);
      }

      const complianceDoc = this.mapComplianceDocument(document);
      if (!header.ComplianceDocumentCurrent || complianceDoc.Id > header.ComplianceDocumentCurrent.Id) {
        if (header.ComplianceDocumentCurrent) {
          header.PreviousDocuments.push(header.ComplianceDocumentCurrent);
        }
        header.ComplianceDocumentCurrent = complianceDoc;
      } else {
        header.PreviousDocuments.push(complianceDoc);
      }

      return headers;
    }, []);
  }

  getSortIndexEntityType(a: PhxConstants.EntityType, b: PhxConstants.EntityType) {
    const entityTypeOrder = [
      PhxConstants.EntityType.WorkOrder,
      PhxConstants.EntityType.UserProfile,
      PhxConstants.EntityType.OrganizationClientRole,
      PhxConstants.EntityType.OrganizationIndependentContractorRole,
      PhxConstants.EntityType.OrganizationSubVendorRole,
      PhxConstants.EntityType.OrganizationLimitedLiabilityCompanyRole
    ];

    return entityTypeOrder.indexOf(a) - entityTypeOrder.indexOf(b);
  }

  getSortIndexComplianceDocumentHeader(a: IComplianceDocumentHeader, b: IComplianceDocumentHeader) {
    // asc
    const sortRequiredType = a.ComplianceDocumentRuleRequiredTypeId - b.ComplianceDocumentRuleRequiredTypeId;
    if (sortRequiredType !== 0) {
      return sortRequiredType;
    }
    // asc
    const sortRuleDisplayName = a.ComplianceDocumentRuleDisplayName > b.ComplianceDocumentRuleDisplayName
      ? 1 : a.ComplianceDocumentRuleDisplayName < b.ComplianceDocumentRuleDisplayName ? -1 : 0;
    if (sortRuleDisplayName !== 0) {
      return sortRequiredType;
    }
    // desc
    return b.Id - a.Id;
  }

  getFilteredDocumentEntityGroupsForTemplateType(
    documentEntityGroups: IComplianceDocumentEntityGroup[],
    templateType: PhxConstants.ComplianceTemplateDocumentType,
    complianceDocumentHederId?: number
  ) {
    return documentEntityGroups.reduce<IComplianceDocumentEntityGroup[]>((relevantGroups, group) => {
      if (this.isGroupRelevantForTemplates(group, templateType, complianceDocumentHederId)) {
        const reduceGroup = {
          ...group,
          Headers: group.Headers.filter(header =>
            this.isHeaderRelevantForTemplates(header, templateType, complianceDocumentHederId)).map(header => {
              return {
                ...header,
                ApplicableTemplateDocuments: header.ApplicableTemplateDocuments.filter(template => template.TemplateType === templateType)
              };
            })
        };
        relevantGroups.push(reduceGroup);
      }
      return relevantGroups;
    }, []);
  }

  isGroupRelevantForTemplates(
    group: IComplianceDocumentEntityGroup,
    templateType: PhxConstants.ComplianceTemplateDocumentType,
    complianceDocumentHeaderId?: number
  ) {
    return group.Headers.some(header => this.isHeaderRelevantForTemplates(header, templateType, complianceDocumentHeaderId));
  }

  getTemplateTypeForStateAction(stateAction: PhxConstants.StateAction) {
    return stateAction === PhxConstants.StateAction.ComplianceDocumentViewSample
      ? PhxConstants.ComplianceTemplateDocumentType.Sample : PhxConstants.ComplianceTemplateDocumentType.Template;
  }

  getFilteredDocumentEntityGroupsForTemplateAction(
    stateAction: PhxConstants.StateAction,
    documentEntityGroups: IComplianceDocumentEntityGroup[],
    complianceDocumentHederId?: number
  ) {
    const templateType = this.getTemplateTypeForStateAction(stateAction);
    return this.getFilteredDocumentEntityGroupsForTemplateType(documentEntityGroups, templateType, complianceDocumentHederId);
  }

  mapDate(date: string) {
    return !date
      ? null
      : moment(date, PhxConstants.DateFormat.API_Datetime)
        .startOf('day')
        .toDate();
  }

  clearList() {
    this.complianceDocuments = null;
  }

  executeStateCommand(commandName: string, payload: any, showLoader: boolean = true): Promise<CommandResponse> {
    return new Promise((resolve, reject) => {
      this.apiService
        .command(commandName, payload, showLoader)
        .then((response: CommandResponse) => {
          if (!response.IsValid) {
            reject(response.ValidationMessages);
          } else {
            resolve(response);
          }
        })
        .catch(ex => {
          reject(ex);
        });
    });
  }

  updateState(complianceDocument: IComplianceDocumentDto | IComplianceDocumentLiteDto) {
    const docId = complianceDocument.ComplianceDocumentId;
    if (this.complianceDocuments) {
      this.complianceDocuments = this.complianceDocuments.map((doc: IComplianceDocumentDto) => {
        if (doc.ComplianceDocumentId === docId) {
          return { ...doc, ...complianceDocument };
        }
        return doc;
      });
    }
  }

  updateAvailableStateActions(entityId: number, availableStateActions: number[]) {
    if (this.complianceDocuments) {
      this.complianceDocuments = this.complianceDocuments.map((doc: IComplianceDocumentDto) => {
        if (doc.ComplianceDocumentId === entityId) {
          return { ...doc, AvailableStateActions: availableStateActions };
        }
        return doc;
      });
    }
  }

  loadComplianceDoc(complianceDocumentId: number): Observable<IComplianceDocumentLiteDto> {
    return this.apiService.query<IComplianceDocumentLiteDto>(`ComplianceDocument/lite/${complianceDocumentId}`);
  }

  getWorkorderInfo(workOrderId: number): Observable<DocHeaderWorkOrderInfo> {
    return this.apiService.query(`ComplianceDocument/work-order-data-lite/${workOrderId}`);
  }

  getComplianceData(documentPublicId: string, complianceDocumentId: string, entityType: string = null): Observable<DocumentComplianceData> {
    return this.apiService.httpPostRequest('ComplianceDocument/compliance-data', {
      documentPublicId, complianceDocumentId, entityType
    },
      this.apiService.apiEndPoint,
      false
    ).pipe(
      map((complianceData: DocumentComplianceData[]) => this.cleanComplianceData(complianceData?.[0]))
    );
  }

  getAllComplianceData(complianceDocumentId: string, entityType: string = null): Observable<DocumentComplianceData[]> {
    return this.apiService.httpPostRequest('ComplianceDocument/compliance-data', {
      complianceDocumentId,
      entityType
    },
      this.apiService.apiEndPoint,
      false
    ).pipe(
      map((allComplianceData: DocumentComplianceData[]) => {
        return allComplianceData.map(complianceData => this.cleanComplianceData(complianceData));
      })
    );
  }

  /** NOTE: compliance data needs some updating before we can display it */
  cleanComplianceData(complianceData?: DocumentComplianceData): DocumentComplianceData | null {
    return complianceData ? {
      ...complianceData,
      documentValidationRuleResults: cleanComplianceRulesResult(complianceData.documentValidationRuleResults, 'infoMessage', this.phxLocalizationService.currentLang)
    } : null;
  }

  getOrgInfo(roleId: number, orgRole: PhxConstants.EntityType): Observable<DocHeaderOrgInfo> {
    let action = '';
    switch (orgRole) {
      case PhxConstants.EntityType.OrganizationIndependentContractorRole:
        action = 'getByOrganizationIndependentContractorRoleId';
        break;
      case PhxConstants.EntityType.OrganizationClientRole:
        action = 'getByOrganizationClientRoleId';
        break;
      case PhxConstants.EntityType.OrganizationInternalRole:
        action = 'getByOrganizationInternalRoleId';
        break;
      case PhxConstants.EntityType.OrganizationSubVendorRole:
        action = 'getByOrganizationSubVendorRoleId';
        break;
      case PhxConstants.EntityType.OrganizationLimitedLiabilityCompanyRole:
        action = 'getByOrganizationLimitedLiabilityCompanyRoleId';
        break;
    }
    return this.apiService.query<DocHeaderOrgInfo>(`org/${action}/${roleId}`);
  }

  getProfileInfo(userProfileId: number): Observable<DocHeaderUserProfileInfo> {
    const queryStr = `$top=1&$filter=EntityId eq ${userProfileId}&$select=FirstName,LastName,EntityId`;
    return this.apiService.query(`report/${PhxConstants.ReportType.PeopleAllReport}?${queryStr}`, true, true).pipe(
      map((res: any) => {
        return res.value[0] ? {
          FirstName: res.value[0].FirstName,
          LastName: res.value[0].LastName,
          UserProfileId: res.value[0].EntityId
        } : null;
      })
    );
  }

  isExpiryDateRequired(action: PhxConstants.StateAction) {
    if (action === PhxConstants.StateAction.ComplianceDocumentApproveSnooze) {
      return true;
    }

    return action === PhxConstants.StateAction.ComplianceDocumentRequestSnooze;
  }

  isCommentRequired(action: PhxConstants.StateAction) {
    return action === PhxConstants.StateAction.ComplianceDocumentRequestSnooze
      || action === PhxConstants.StateAction.ComplianceDocumentRequestExemption;
  }

  updateStateActionRequestExemptionNoDocument(action: StateAction) {
    action.actionId = PhxConstants.StateAction.ComplianceDocumentRequestExemptionNoDocument;
    action.commandName = this.codeValueService.getCodeValueCode(action.actionId, CodeValueGroups.StateAction);
  }

  checkDocumentUnmaskLimit(documentId: number, piiRestrictionLevelId: number) {
    return lastValueFrom(this.apiService.query(`PII/CheckDocumentUnmaskLimit/RestrictionLevel/${piiRestrictionLevelId}/Entity/${documentId}`));
  }

  getExcludedClientListForDocumentComplianceData(): Observable<number[]> {
    if (!this.excudedDocumentComplianceDataClientList) {
      return this.apiService.httpGetRequest<GlobalConfiguration<ClientExclusion>[]>('globalConfiguration/Configuration/ComplianceDataExclusionList',
        environment.adminServiceApiEndpoint, false).pipe(
          map(clientExclusionData => clientExclusionData?.[0]?.Data?.Ids ?? []),
          tap(clientExclusionIds => this.excudedDocumentComplianceDataClientList = clientExclusionIds),
          catchError(() => of([])
          )
        );
    } else {
      return of(this.excudedDocumentComplianceDataClientList);
    }
  }

  private param(oDataParams) {
    return oDataParams ? `?${oDataParams}` : '';
  }

  private setIsLoadingComplianceDocumentList(entityTypeId: number, entityId: number, isLoading: boolean) {
    ComplianceDocumentService.isLoadingComplianceDocumentList[`${entityTypeId}_${entityId}`] = isLoading;
  }

  private getIsLoadingComplianceDocumentList(entityTypeId: number, entityId: number) {
    return ComplianceDocumentService.isLoadingComplianceDocumentList[`${entityTypeId}_${entityId}`];
  }

  private isHeaderRelevantForTemplates(
    header: IComplianceDocumentHeader,
    templateType: PhxConstants.ComplianceTemplateDocumentType,
    complianceDocumentHeaderId?: number
  ) {
    if (complianceDocumentHeaderId != null && header.Id !== complianceDocumentHeaderId) {
      return false;
    }
    return header.ApplicableTemplateDocuments.filter(x => x.TemplateType === templateType).length > 0;
  }

  private mapComplianceDocument(document: IComplianceDocumentDto): IComplianceDocument {
    return {
      Id: document.ComplianceDocumentId,
      ComplianceDocumentStatusId: document.ComplianceDocumentStatusId,
      ComplianceDocumentSnoozeExpiryDate: this.mapDate(document.ComplianceDocumentSnoozeExpiryDate),
      ComplianceDocumentExpiryDate: this.mapDate(document.ComplianceDocumentExpiryDate),
      AvailableStateActions: document.AvailableStateActions,
      LatestUserComment: document.LatestUserComment,
      Documents: document.Documents
    };
  }

  private mapComplianceTemplates(complianceTemplates: IApplicableComplianceTemplateDto[]): IApplicableComplianceTemplate[] {
    const templates = complianceTemplates
      .filter(x => x.ComplianceTemplateTemplateDocumentPublicId != null)
      .map(x => this.mapComplianceTemplateDocument(x,
        PhxConstants.ComplianceTemplateDocumentType.Template,
        x.ComplianceTemplateSampleDocumentPublicId, x.ComplianceTemplateTemplateDocumentName));

    const samples = complianceTemplates
      .filter(x => x.ComplianceTemplateSampleDocumentPublicId != null)
      .map(x => this.mapComplianceTemplateDocument(x,
        PhxConstants.ComplianceTemplateDocumentType.Sample,
        x.ComplianceTemplateSampleDocumentPublicId, x.ComplianceTemplateSampleDocumentName));

    return [...templates, ...samples];
  }

  private mapComplianceTemplateDocument(
    complianceTemplate: IApplicableComplianceTemplateDto,
    templateType: PhxConstants.ComplianceTemplateDocumentType,
    documentPublicId: string,
    documentName: string
  ): IApplicableComplianceTemplate {
    return {
      Id: complianceTemplate.ComplianceTemplateId,
      TemplateType: templateType,
      Name: complianceTemplate.ComplianceTemplateName,
      DocumentPublicId: documentPublicId,
      DocumentName: documentName
    };
  }
}
