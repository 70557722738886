import { NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { LayoutComponent } from './layout-component/layout.component';
import { PhoenixCommonModule } from '../common/PhoenixCommon.module';
import { TopNavMenuModule } from '../top-nav-menu/top-nav-menu.module';
import { GlobalSearchBarModule } from '../global-search/global-search-bar/global-search-bar.module';
import { RouterModule } from '@angular/router';
import { BookmarkSideMenuComponent } from '../bookmark-side-menu/bookmark-side-menu.component';
import { FormsModule } from '@angular/forms';
import { TodoListComponent } from '../sidenav/components/todo-list/todo-list.component';
import { DxListModule } from 'devextreme-angular';
import { MatIconModule } from '@angular/material/icon';
import { MiniNavigationComponent } from '../sidenav/components/mini-navigation/mini-navigation.component';
import { PanelBarModule } from '../common/panel-bar/panel-bar.module';

const components = [
  HeaderComponent,
  SidenavComponent,
  BreadcrumbsComponent,
  LayoutComponent,
  BookmarkSideMenuComponent,
  TodoListComponent,
  MiniNavigationComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    PhoenixCommonModule,
    FormsModule,
    TopNavMenuModule,
    GlobalSearchBarModule,
    RouterModule,
    DxListModule,
    MatIconModule,
    PanelBarModule
  ],
  exports: components
})
export class LayoutModule {
}
