import { Component, Input } from '@angular/core';
import { IRuleGroup, IRuleResult, RuleStrictness } from 'src/app/common/model/rule-engine';

import { uuid } from 'src/app/common/utility/uuid';

import { RuleComplianceOverride } from '../../../rule-compliance/models';

@Component({
  selector: 'app-panel-checklist-rule',
  templateUrl: './panel-checklist-rule.component.html',
  styleUrls: ['./panel-checklist-rule.component.less']
})
export class PanelChecklistRuleComponent {
  @Input() rule: IRuleResult;
  @Input() ruleGroup?: IRuleGroup;
  @Input() styleAsHeading: boolean = false;
  @Input() ruleComplianceOverride?: RuleComplianceOverride;

  readonly ruleStrictness = RuleStrictness;

  readonly tooltipId = `ruleinfo-${uuid.create()}`;
}
