import { Component, Input, OnInit, TemplateRef, Inject } from '@angular/core';
import { RuleComplianceRuleResult } from '../../models';
import { PhxConstants } from 'src/app/common/PhoenixCommon.module';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { filter, mergeMap, Observable, takeUntil } from 'rxjs';
import { ICommonListsItem } from 'src/app/common/lists';
import { RuleOverrideReason } from '../../internal-models';
import { COMPLIANCE_RULE_OVERRIDE_REASONS, ComplianceRuleOverrideReasons } from 'src/app/common/services/compliance-rule-override-reasons.provider';

@Component({
  selector: 'app-rule-compliance-info-table',
  templateUrl: './rule-compliance-info-table.component.html',
  styleUrls: ['./rule-compliance-info-table.component.less']
})
export class RuleComplianceInfoTableComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() rule: RuleComplianceRuleResult;
  @Input() overrideReasonTemplate: TemplateRef<void> | null = null;

  actionedBy: ICommonListsItem | null;
  reasonCodeDisplayText: string;

  public dateFormat = PhxConstants.DateFormat;

  constructor(
    private listsObservableService: CommonListsObservableService,
    @Inject(COMPLIANCE_RULE_OVERRIDE_REASONS) private complianceRuleOverrideReasons$: Observable<ComplianceRuleOverrideReasons>
  ) {
    super();
  }

  ngOnInit() {
    this.listsObservableService.listUserProfileInternal$()
    .pipe(
      filter(profiles => profiles && Boolean(this.rule.ruleComplianceOverride)),
      mergeMap(profiles => profiles),
      filter(profile => profile.Id.toString() === this.rule.ruleComplianceOverride.actionedByProfileId),
      takeUntil(this.isDestroyed$)
    )
    .subscribe(profile => {
      this.actionedBy = profile;
    });

    this.complianceRuleOverrideReasons$.pipe(takeUntil(this.isDestroyed$))
    .subscribe(reasons => {
      if (this.rule.ruleComplianceOverride) {
        this.reasonCodeDisplayText = this.getReasonCodeDisplayText(reasons.ManualFailReasons, reasons.ManualPassReasons);
      }
    });
  }

  getReasonCodeDisplayText(manualFailReasons: RuleOverrideReason[], manualPassReasons: RuleOverrideReason[]) {
    const reasonCode = this.rule.ruleComplianceOverride.reasonCode;
    const reasonCodeLookup = this.rule.ruleComplianceOverride.validByUser ? manualPassReasons : manualFailReasons;
    return reasonCodeLookup.find(reason => reason.Code === reasonCode)?.DisplayText ?? reasonCode;
  }
}
