import { PhxDataTableSelectionMode } from './phx-data-table-selection-mode';
import { PhxDataTableSelectallMode } from './phx-data-table-selectall-mode';
import { PhxDataTableShowCheckboxesMode } from './phx-data-table-show-checkboxes-mode';
import { PhxDataTableStateSavingMode } from './phx-data-table-state-saving-mode';
import { PhxDataTableScrollMode } from './phx-data-table-scroll-mode';
import { Row } from 'devextreme/ui/data_grid';

export class PhxDataTableConfiguration {
  selectionMode?: PhxDataTableSelectionMode = PhxDataTableSelectionMode.Single;
  scrollMode?: PhxDataTableScrollMode = PhxDataTableScrollMode.Virtual;
  selectAllMode?: PhxDataTableSelectallMode = PhxDataTableSelectallMode.Page;
  showCheckBoxesMode?: PhxDataTableShowCheckboxesMode = PhxDataTableShowCheckboxesMode.Always;
  allowSelectAll = true;
  allowEditing = false;
  showFilter = true;
  showNavigationButtons = false;
  showClearFilterButton = true;
  showSearch = true;
  showGrouping = true;
  showColumnChooser = true;
  columnHidingEnabled = false;
  stateSavingMode?: PhxDataTableStateSavingMode = PhxDataTableStateSavingMode.Customizable;
  enableExport = false;
  showTotalCount = true;
  pageSize = 25;
  noDataText?: string;
  enableMasterDetail = false;
  showOpenInNewTab = false;
  masterDetailTemplateName = 'detail';
  masterDetailAutoExpandAll = false;
  loadPanelText?: string;
  loadPanelEnabled?: any = 'auto'; // 'auto', true, false
  saveUserFilters = true;
  showBorders = true;
  rowAlternationEnabled = true;
  rowHighlightingConfig?: RowHighlightingConfig = null;
  showToolbar = true;
  deferred = false;
  hidePager = false;
  groupingAutoExpandAll = true;
  groupingExpandMode = 'buttonClick';
  groupingAllowCollapsing = false;
  // Generate customize cell function to prepare content for export from rows
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customizeExportData = (rows: Row[]) => (a: any) => undefined;

  constructor(params: Partial<PhxDataTableConfiguration>) {
    Object.assign(this, params);
  }
}

export class RowHighlightingConfig {
  fieldName = 'IsTest';
  cssClass = 'testRowClass';
}

// for more grid editing config options see
// https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/editing/
export interface PhxDataTableEditingConfig {
  editing: {
    texts?: {
      addRow?: string,
      cancelAllChanges?: string,
      cancelRowChanges?: string,
      confirmDeleteMessage?: string,
      confirmDeleteTitle?: string,
      deleteRow?: string,
      editRow?: string,
      saveAllChanges?: string,
      saveRowChanges?: string,
      undeleteRow?: string,
      validationCancelChanges?: string
    }
  };
}



