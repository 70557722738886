import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import * as uuidModule from 'uuid';

import { CommandResponse, ConcurrencyError, PageLoader, PageLoaderApi } from '../model';
import { CookieService } from './cookie.service';
import { UtilityService } from './utility-service.service';
import { SignalrService } from './signalr.service';
import { environment } from '../../../environments/environment';
import { EventService } from './event.service';
import { LoadingSpinnerService } from '../loading-spinner/service/loading-spinner.service';
import { TokenAuth } from '../model/auth';
import { StorageService } from 'ngx-webstorage-service';
import { AppStorageService } from './app-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  @Output() OnConcurrencyError: EventEmitter<ConcurrencyError> = new EventEmitter<ConcurrencyError>();
  apiEndPoint: string;
  rawApiEndPoint: string;
  private readonly instanceUiId: string;
  private pageLoader: PageLoader;
  private apiDuration: {
    [uuid: string]: PageLoaderApi;
  } = {};

  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private cookieSvc: CookieService,
    private utilitySvc: UtilityService,
    private signalrService: SignalrService,
    private http: HttpClient,
    private eventSvc: EventService,
    @Inject(AppStorageService) private storageService: StorageService
  ) {
    this.apiEndPoint = environment.apiUrl + 'api';
    this.rawApiEndPoint = environment.apiUrl.replace(/\/$/, '');
    this.instanceUiId = uuidModule.v4();
    this.signalrService.onPrivate(
      'HandlerExecuteException',
      () => {
        this.loadingSpinnerService.hideAll();
      },
      true
    );

    this.signalrService.onPrivate(
      'ConcurrencyNotifyEvent',
      (event, data) => {
        if (data.IsOwner) {
          this.OnConcurrencyError.emit(data);
        }
      },
      true
    );

    this.eventSvc.subscribe('ConcurrencyNotifyEvent', (e: any) => {
      this.OnConcurrencyError.emit(e);
    });
  }

  get browserName(): string {
    if (navigator.userAgent.match(/OPR/i)) {
      return 'Opera';
    }
    if (navigator.userAgent.match(/Firefox/i)) {
      return 'Firefox';
    }
    if (navigator.userAgent.match(/Trident/i) || navigator.userAgent.match(/MSIE/i)) {
      return 'IE';
    }
    if (navigator.userAgent.match(/Edge/i)) {
      return 'Legacy Edge';
    }
    if (navigator.userAgent.match(/Edg/i)) {
      return 'Edge';
    }
    if (navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i)) {
      return 'Safari';
    }
    if (navigator.userAgent.match(/Chrome/i)) {
      return 'Chrome';
    }
    if (navigator.userAgent.match(/like Mac OS X/i) && navigator.userAgent.match(/Mobile/i)) {
      return 'iOS';
    }
    return 'Unknown';
  }

  get operatingSystem(): string {
    if (navigator.userAgent.match(/Android/i)) {
      return 'Android';
    }
    if (navigator.userAgent.match(/webOS/i)) {
      return 'WebOS';
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      return 'iOS - iPhone';
    }
    if (navigator.userAgent.match(/iPad/i)) {
      return 'iOS - iPad';
    }
    if (navigator.userAgent.match(/iPod/i)) {
      return 'iOS - iPod';
    }
    if (navigator.userAgent.match(/BlackBerry/i)) {
      return 'BlackBerry';
    }
    if (navigator.userAgent.match(/Windows Phone/i)) {
      return 'Windows Phone';
    }
    if (navigator.userAgent.match(/Macintosh/i)) {
      return 'MacOS';
    }
    if (navigator.userAgent.match(/X11/i)) {
      return 'UNIX';
    }
    if (navigator.userAgent.match(/Linux/i)) {
      return 'Linux';
    }
    if (navigator.userAgent.match(/Windows/i)) {
      if (navigator.userAgent.match(/Windows NT 6.1/i)) {
        return 'Windows 7';
      }
      if (navigator.userAgent.match(/Windows NT 6.2/i)) {
        return 'Windows 8';
      }
      if (navigator.userAgent.match(/Windows NT 6.3/i)) {
        return 'Windows 8.1';
      }
      if (navigator.userAgent.match(/Windows NT 10.0/i)) {
        return 'Windows 10';
      }
      return 'Windows';
    }
    return 'Unknown';
  }

  public pageLoaderStart(url: string) {
    const cookie: any = this.cookieSvc.getProfileIdFromCookie();
    const userProfileId = cookie ? cookie.profileId : -1;

    this.pageLoader = {
      PageLoaderUiId: uuidModule.v4(),
      StartUrl: url,
      StartTime: new Date(),
      UserProfileId: userProfileId,
      InstanceUiId: this.instanceUiId
    };
  }

  public pageLoaderEnd(url: string) {
    if (!this.pageLoader) {
      return;
    }
    this.pageLoader.EndUrl = url;
    this.pageLoader.EndTime = new Date();
    this.pageLoader.Duration = this.pageLoader.EndTime.getTime() - this.pageLoader.StartTime.getTime();
    this.pageLoaderLog();
  }

  public async command(command: string, data: any = null, showLoader: boolean = true): Promise<CommandResponse> {
    if (showLoader) {
      this.loadingSpinnerService.show();
    }
    return new Promise((resolve, reject) => {
      const uuid = uuidModule.v4();
      data = data || {};
      const cmd: ICommand = { ...data, CommandName: command, CommandId: uuid };

      this.signalrService.addToOwnerDictionary(uuid);

      this.signalrService.onDisconnect(reject);

      this.signalrService.onPrivate(cmd.CommandName + '.complete.' + uuid, (commandName, response) => {
        if (response.CommandId === uuid) {
          this.apiDurationEnd(uuid, showLoader);
          if (response.IsValid === false) {
            reject(response);
            this.signalrService.leave(uuid);
            if (typeof response.unregister === 'function') {
              response.unregister();
            }
            if (showLoader) {
              this.loadingSpinnerService.hideAll();
            }
          } else {
            resolve(response);
            this.signalrService.leave(uuid);
            if (typeof response.unregister === 'function') {
              response.unregister();
            }
            if (showLoader) {
              this.loadingSpinnerService.hide();
            }
          }
        }
      });

      this.signalrService.onPrivate(cmd.CommandName + '.error.' + uuid, (commandName, response) => {
        if (response.CommandId === uuid) {
          this.apiDurationEnd(uuid, showLoader);
          reject(response);
          this.signalrService.leave(uuid);
          if (typeof response.unregister === 'function') {
            response.unregister();
          }
          if (showLoader) {
            this.loadingSpinnerService.hideAll();
          }
        }
      });

      let tokenRefreshEvent: Observable<TokenAuth | null> = of(null);
      if (!this.signalrService.isConnected() && this.signalrService.isTokenExpired()) {
        tokenRefreshEvent = this.refreshToken();
      }

      tokenRefreshEvent.subscribe(() => {
        this.signalrService.join(uuid).then(
          () => {
            this.apiDurationStart(uuid, null, cmd.CommandName);
            this.submitCommand(cmd).catch(err => {
              this.apiDurationEnd(uuid, showLoader);
              this.loadingSpinnerService.hideAll();
              reject(err);
            });
          },
          () => {
            // TODO: What to do if we fail to join channel?
          }
        );
      });
    });
  }

  downloadFile(url: string) {
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  refreshToken() {
    const refreshToken = this.storageService.get('RefreshToken');
    if (refreshToken) {
      const body = new URLSearchParams();
      body.set('grant_type', 'refresh_token');
      body.set('refresh_token', refreshToken);
      const url = this.rawApiEndPoint + '/token';
      const appId = this.storageService.get('AppId');
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/x-www-form-urlencoded',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'App-Id': appId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Browser-Name': this.browserName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'OS-Name': this.operatingSystem
        })
      };

      return this.http
        .post<TokenAuth>(url, body.toString(), options)
        .pipe(switchMap(response => this.handleTokenAuthResponse(response.access_token, response.refresh_token, response['.expires']).pipe(map(() => response))));
    } else {
      return throwError(() => new Error('Token not found'));
    }
  }

  handleTokenAuthResponse(accessToken: string, refreshToken: string, accessTokenExpiry: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (!accessToken || !refreshToken) {
        return observer.error(false);
      }
      this.storageService.set('BearerToken', accessToken);
      this.storageService.set('RefreshToken', refreshToken);
      this.storageService.set('BearerTokenExpiresOn', accessTokenExpiry);
      this.signalrService.setAccessToken(accessToken);
      this.signalrService.connect().then(
        () => {
          observer.next(true);
          observer.complete();
        },
        e => observer.error(e)
      );
    });
  }

  public queryAZSearch<T>(path: string) {
    return this.query<T>(path, true, true);
  }

  public getBlob<Blob>(path: string, apiEndPoint = this.apiEndPoint): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(apiEndPoint + '/' + path, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  public postAndGetBlob<Blob>(path: string, body, apiEndPoint = this.apiEndPoint): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(apiEndPoint + '/' + path, body, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  /**
   * @deprecated Use `query` as it returns an Observable and do not use `from` Rxjs operator as is used with this method
   */
  public async queryWithPromise<T>(path: string, showLoader: boolean = true, contentType: string = 'application/json', useAZSearchAPI: boolean = false): Promise<T> {
    const uuid = uuidModule.v4();
    if (showLoader) {
      this.loadingSpinnerService.show();
    }
    return new Promise<T>((resolve, reject) => {
      switch (contentType) {
        case 'text/html':
          this.apiDurationStart(uuid, path, null);
          const headersAccept = new HttpHeaders({ Accept: contentType });
          this.http.get(this.apiEndPoint + '/' + path, { headers: headersAccept, responseType: 'text' }).subscribe({
            next: (response: any) => {
              this.apiDurationEnd(uuid, showLoader);
              if (showLoader) {
                this.loadingSpinnerService.hide();
              }
              resolve(response);
            },
            error: error => {
              this.apiDurationEnd(uuid, showLoader);
              if (showLoader) {
                this.loadingSpinnerService.hideAll();
              }
              reject(error);
            }
          });
          break;
        case 'application/json':
          this.apiDurationStart(uuid, path, null);
          const apiEndPoint = (useAZSearchAPI ? environment.reportServiceApiEndpoint : this.apiEndPoint) + '/';
          this.http.get(apiEndPoint + path).subscribe({
            next: (response: any) => {
              this.apiDurationEnd(uuid, showLoader);
              if (showLoader) {
                this.loadingSpinnerService.hide();
              }
              resolve(response);
            },
            error: error => {
              this.apiDurationEnd(uuid, showLoader);
              if (showLoader) {
                this.loadingSpinnerService.hideAll();
              }
              reject(error);
            }
          });
          break;
        default:
          throw new Error(`api.service.get does not know how to handle parameter contentType=${contentType}`);
      }
    });
  }

  query<T>(path: string, showLoader: boolean = true, useAZSearchAPI: boolean = false) {
    if (showLoader) {
      this.loadingSpinnerService.show();
    }
    const uuid = uuidModule.v4();
    this.apiDurationStart(uuid, path, null, showLoader);
    const apiEndPoint = (useAZSearchAPI ? environment.reportServiceApiEndpoint : this.apiEndPoint) + '/';
    let requestCancelled = true;
    // finalize is called only when the subscriber completes the execution, or an error occurred or request was cancelled
    // we need to use tap and catchError to get more accurate http call end duration as they don't involve invoking the subscriber
    // during finalize, we only care about hiding the loading screen if the request was cancelled.
    // In that case we should also make a distinction in api logs that the request was cancelled
    return this.http.get<T>(apiEndPoint + path).pipe(
      tap(() => {
        requestCancelled = false;
        this.apiDurationEnd(uuid, showLoader);
        if (showLoader) {
          this.loadingSpinnerService.hide();
        }
      }),
      catchError(error => {
        requestCancelled = false;
        this.apiDurationEnd(uuid, showLoader);
        if (showLoader) {
          this.loadingSpinnerService.hideAll();
        }
        return throwError(() => error);
      }),
      finalize(() => {
        if (requestCancelled) {
          this.apiDurationEnd(uuid, showLoader, true);
          if (showLoader) {
            this.loadingSpinnerService.hide();
          }
        }
      })
    );
  }

  httpPostRequest<T>(path: string, body, apiEndPoint = this.apiEndPoint, showSpinner = true): Observable<T> {
    if (showSpinner) {
      this.loadingSpinnerService.show();
    }
    return this.http.post<T>(apiEndPoint + '/' + path, body).pipe(
      finalize(() => {
        if (showSpinner) {
          this.loadingSpinnerService.hide();
        }
      })
    );
  }

  fetchRequest(path: string, headers, apiEndPoint = this.apiEndPoint, showSpinner = true) {
    if (showSpinner) {
      this.loadingSpinnerService.show();
    }

    const token = { authorization: 'Bearer ' + this.storageService.get('BearerToken') };
    headers.headers = { ...headers.headers, ...token };
    return fetch(apiEndPoint + '/' + path, headers);
  }

  httpPatchRequest<T>(path: string, body, apiEndPoint = this.apiEndPoint, showSpinner = true): Observable<T> {
    if (showSpinner) {
      this.loadingSpinnerService.show();
    }
    return this.http.patch<T>(apiEndPoint + '/' + path, body).pipe(
      finalize(() => {
        if (showSpinner) {
          this.loadingSpinnerService.hide();
        }
      })
    );
  }

  httpPutRequest<T>(path: string, body, apiEndPoint = this.apiEndPoint, showSpinner = true): Observable<T> {
    if (showSpinner) {
      this.loadingSpinnerService.show();
    }
    return this.http.put<T>(apiEndPoint + '/' + path, body).pipe(
      finalize(() => {
        if (showSpinner) {
          this.loadingSpinnerService.hide();
        }
      })
    );
  }

  httpDeleteRequest<T>(path: string, apiEndPoint = this.apiEndPoint, showSpinner = true): Observable<T> {
    if (showSpinner) {
      this.loadingSpinnerService.show();
    }
    return this.http.delete<T>(apiEndPoint + '/' + path).pipe(
      finalize(() => {
        if (showSpinner) {
          this.loadingSpinnerService.hide();
        }
      })
    );
  }

  httpGetRequest<T>(path: string, apiEndPoint?: string, showSpinner?: boolean): Observable<T>;
  httpGetRequest<T>(ags: GetRequestArguments): Observable<T>;
  httpGetRequest<T>(pathOrArguments: string | GetRequestArguments, apiEndpoint = this.apiEndPoint, showSpinner = true): Observable<T> {
    let path: string;
    let params: HttpParams;
    if(typeof pathOrArguments === 'string'){
      path = pathOrArguments;
    } else {
      path = pathOrArguments.path;
      apiEndpoint = pathOrArguments.apiEndpoint ?? this.apiEndPoint;
      showSpinner = pathOrArguments.showSpinner ?? true;
      params = pathOrArguments.params;
    }

    if (showSpinner) {
      this.loadingSpinnerService.show();
    }
    return this.http.get<T>(apiEndpoint + '/' + path, { params }).pipe(
      finalize(() => {
        if (showSpinner) {
          this.loadingSpinnerService.hide();
        }
      })
    );
  }

  public onPrivate(eventName: any, callback: any, queueIfDisconnected?: boolean) {
    return this.signalrService.onPrivate(eventName, callback, queueIfDisconnected);
  }

  public onPublic(eventName: any, callback: any, queueIfDisconnected?: boolean) {
    return this.signalrService.onPublic(eventName, callback, queueIfDisconnected);
  }

  public entitySubscribe(entityType: number, entityId: number, callback: any): Subscription {
    return this.signalrService.entitySubscribe(entityType, entityId, callback);
  }

  public entityUnsubscribe(entityType: number, entityId: number) {
    this.signalrService.entityUnsubscribe(entityType, entityId);
  }

  public urlWithRoom(path: string, commandId: string = null) {
    if (!path) {
      return;
    }

    if (!commandId) {
      commandId = this.utilitySvc.createUuid();
    }
    this.signalrService.addToOwnerDictionary(commandId);

    const mainRoot = this.rawApiEndPoint.replace(/\/$/, '') + '/api/';

    let result = path.replace(/^\/|\/$/g, '');

    if (result.indexOf(mainRoot) === -1) {
      result = mainRoot + result;
    }

    const toResolve = result + '?commandId=' + commandId;
    this.signalrService.join(commandId).then(
      () => {},
      () => {}
    );

    return toResolve;
  }

  public isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  private pageLoaderLog() {
    const allowLogging: boolean = environment.pageLoader ? environment.pageLoader.allowLogging === 'true' : false;
    const apiUrl: string = environment.pageLoader ? environment.pageLoader.apiUrl || '' : '';
    if (allowLogging) {
      const payload = {
        ...this.pageLoader,
        StartTime: this.pageLoader.StartTime ? this.pageLoader.StartTime.toJSON() : null,
        EndTime: this.pageLoader.EndTime ? this.pageLoader.EndTime.toJSON() : null
      };
      if (apiUrl !== '') {
        this.http.post(`${apiUrl}/performance/savePageLoader`, payload).subscribe({
          next: () => {},
          error: err => {
            console.log(err);
          }
        });
      } else {
        console.log(payload);
      }
    }
    this.pageLoader = null;
  }

  private apiDurationStart(uuid: string, path: string, commandName: string, pageLoaderDisplayed = true) {
    if (!this.apiDuration) {
      this.apiDuration = {};
    }

    const cookie: any = this.cookieSvc.getProfileIdFromCookie();
    const userProfileId = cookie ? cookie.profileId : -1;

    this.apiDuration[uuid] = {
      PageLoaderApiUiId: uuid,
      PageLoaderUiId: this.pageLoader ? this.pageLoader.PageLoaderUiId : null,
      Url: path,
      CommandName: commandName,
      StartTime: new Date(),
      UserProfileId: userProfileId,
      PageLoaderDisplayed: pageLoaderDisplayed,
      InstanceUiId: this.instanceUiId
    };
  }

  private apiDurationEnd(uuid: string, showLoader: boolean, wasRequestCancelled = false) {
    const apiDuration = this.apiDuration ? this.apiDuration[uuid] : null;
    if (apiDuration) {
      apiDuration.EndTime = new Date();
      apiDuration.Duration = apiDuration.EndTime.getTime() - apiDuration.StartTime.getTime();
      if (wasRequestCancelled) {
        apiDuration.RequestCancelled = true;
      }
      if (showLoader && !apiDuration.PageLoaderUiId && this.pageLoader) {
        apiDuration.PageLoaderUiId = this.pageLoader.PageLoaderUiId;
      }
      this.apiDurationLog(uuid);
    }
  }

  private apiDurationLog(uuid: string) {
    const allowLogging: boolean = environment.pageLoader ? environment.pageLoader.allowLogging === 'true' : false;
    const apiUrl: string = environment.pageLoader ? environment.pageLoader.apiUrl || '' : '';
    const apiDuration = this.apiDuration ? this.apiDuration[uuid] : null;
    if (apiDuration) {
      if (allowLogging) {
        const payload = {
          ...apiDuration,
          StartTime: apiDuration.StartTime ? apiDuration.StartTime.toJSON() : null,
          EndTime: apiDuration.EndTime ? apiDuration.EndTime.toJSON() : null
        };
        if (apiUrl !== '') {
          this.http.post(`${apiUrl}/performance/savePageLoaderAPi`, payload).subscribe({
            next: () => {},
            error: err => {
              console.log(err);
            }
          });
        } else {
          console.log(payload);
        }
      }
      delete this.apiDuration[uuid];
    }
  }

  private submitCommand(cmd: ICommand) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiEndPoint + '/command/' + cmd.CommandName, cmd, { observe: 'response' }).subscribe({
        next: (response: HttpResponse<any>) => {
          if (response.status === 200) {
            // do something?
          } else if (response.status === 401) {
            // Unauthorized
            const msg = response.body ? response.body.Message : 'Unauthorized Access';
            reject({ msg, status: response.status, response: undefined });
            // redirect to login page?
          }
        },
        error: err => {
          if (err.status === 400) {
            reject({ status: err.status, response: err.error, ModelState: err.error?.ModelState ? err.error.ModelState : null });
          } else {
            const isConcurrencyException =
              err.status === 500 && (err.body?.ExceptionMessage === 'Concurrency exception' || err.error?.ExceptionMessage === 'Concurrency exception');
            reject({ status, response: undefined, isConcurrencyException });
          }
        }
      });
    });
  }
}

interface ICommand {
  CommandId: string;
  CommandName: string;
}

interface GetRequestArguments {
  path: string;
  apiEndpoint?: string;
  showSpinner?: boolean;
  params?: HttpParams;
}
