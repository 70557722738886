<div class="status-icon-container">
    <mat-icon
        class="ck-override"
        title="Overridden"
        *ngIf="showOverride"
        [style.visibility]="overriden ? 'visible' : 'hidden'"
        [attr.aria-hidden]="!overriden"
    >
        edit
    </mat-icon>
    <mat-icon 
        class="compliance-rule-icon"
        [ngClass]="class"
        [title]="text"
    >
        {{icon}}
    </mat-icon>
    <span class="cdk-visually-hidden">{{text}}</span>
</div>
