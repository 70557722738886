import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../services/api.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IRuleResult, RuleStrictness, ComplianceStatus, IRuleCategory, IRuleGroup } from '../../../model/rule-engine';
import { BaseComponentOnDestroy } from '../../../epics/base-component-on-destroy';
import { catchError, debounceTime, filter, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PhxSideBarService } from '../../../services/phx-side-bar.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { PhxConstants, PhxFormControlLayoutType } from '../../../model';
import { GoogleAnalyticsService } from '../../../services/google-analytics/google-analytics.service';
import { WindowRefService } from '../../../services/WindowRef.service';
import { cleanComplianceRulesResult } from '../../../../compliance/shared/utilities/clean-compliance-rules-result/clean-compliance-rules-result.util';
import { PhxLocalizationService } from '../../../services/phx-localization.service';
import { GV_HIDDEN_RULESET_IDS, GVHiddenRulesetIdsProvider } from '../../../services/gorilla-vision-hidden-rules-configuration.provider';
import { ComplianceDataService } from '../../../data-services/compliance-data/compliance-data.service';
import { RuleComplianceOverride } from '../../../rule-compliance/models';
import {
  convertComplianceRuleOverrideToRuleComplianceOverride
} from '../../../utility/convert-compliance-rule-override-to-rule-compliance-override/convert-compliance-rule-override-to-rule-compliance-override.util';
import { ComplianceRuleOverride } from 'src/app/common/data-services/compliance-data/models';

type RuleResultWithGroups = IRuleResult & IRuleGroup;
@Component({
  selector: 'app-phx-panel-checklist',
  templateUrl: './phx-panel-checklist.component.html',
  styleUrls: ['./phx-panel-checklist.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GVHiddenRulesetIdsProvider]
})
export class PhxPanelChecklistComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {

  /** NOTE: used for 'refresh' button spinner visual cue */
  loadingList = true;
  loadingColor = '#333';
  errorLoadingRules = false;

  /** NOTE: entity type being checked - WO, org, onboarding, etc */
  currentEntityValue: any = null;
  entityType: string;

  /** NOTE: list of rule groups */
  ruleGroups: Array<IRuleGroup>;
  /** NOTE: list of rule groups when the user is using keyword filter */
  filteredRuleGroups: Array<IRuleGroup>;
  ComplianceStatus = ComplianceStatus;

  currentComplianceStatus: ComplianceStatus;

  filterStatus: boolean[] = [true, true, true];

  warningTotal: number;
  compliantTotal: number;
  nonCompliantTotal: number;
  ruleCategories: IRuleCategory[] = [];

  hidingCompliantRules = false;
  hidingWarningRules = false;
  hidingNoncompliantRules = false;
  hidingEverything = false;

  hiddenRulesetIds: string[] = [];

  /** NOTE: keyword search form */
  form = this.formBuilder.group({
    keyword: null
  });
  inputOnlyLayoutType: PhxFormControlLayoutType = PhxFormControlLayoutType.InputOnly;
  readonly ruleResultComplianceOverrides$ = new BehaviorSubject<Map<IRuleResult, RuleComplianceOverride>>(new Map());

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private phxSidebarService: PhxSideBarService,
    private formBuilder: UntypedFormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private winRef: WindowRefService,
    private phxLocalizationService: PhxLocalizationService,
    private complianceDataService: ComplianceDataService,
    @Inject(GV_HIDDEN_RULESET_IDS) public hiddenRulesetIds$: Observable<string[]>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initHiddenRules();
    this.initEntityTypeChange();
    this.initEntityChange();
    this.initRefreshChecklistEmit();
    this.initKeyWordChange();
    this.initRefreshComplianceDocumentRulesEmit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  trackByFn(index: number, item: IRuleGroup) {
    return item.category;
  }

  resetFilters() {
    this.filterStatus = [true, true, true];
    this.applyFiltersAndSearch();
  }

  applyFiltersAndSearch() {
    let temporaryFilter: Array<IRuleResult> = [];
    this.filteredRuleGroups = cloneDeep(this.ruleGroups);

    this.hidingEverything = false;

    this.hidingCompliantRules = !this.filterStatus[0];
    this.hidingWarningRules = !this.filterStatus[2];
    this.hidingNoncompliantRules = !this.filterStatus[1];


    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.filteredRuleGroups.length; i++) {
      temporaryFilter = [];
      for (let j = 0; j < this.filterStatus.length; j++) {
        if (j === 0 && this.filterStatus[j] === true) {
          temporaryFilter.push(...this.getCompliantRules(this.filteredRuleGroups[i].rules));
        }
        else if (j === 1 && this.filterStatus[j] === true) {
          temporaryFilter.push(...this.getNonCompliantRules(this.filteredRuleGroups[i].rules));
        }
        else if (j === 2 && this.filterStatus[j] === true) {
          temporaryFilter.push(...this.getWarningRules(this.filteredRuleGroups[i].rules));
        }
      }

      if (this.form.controls.keyword.value) {
        const keyWord = this.form.controls.keyword.value?.toLowerCase();
        temporaryFilter = temporaryFilter
          .filter(x => x.ruleText.toLowerCase().includes(keyWord));
      }

      temporaryFilter.sort((ruleA, ruleB) => {
        const ruleAHasDocumentRuleSetId = !!ruleA.documentRuleSetId;
        const ruleBHasDocumentRuleSetId = !!ruleB.documentRuleSetId;

        if (!ruleAHasDocumentRuleSetId && ruleBHasDocumentRuleSetId) {
          // ruleA should come before ruleB (ruleA has no documentRuleSetId)
          return -1;
        } else if (ruleAHasDocumentRuleSetId && !ruleBHasDocumentRuleSetId) {
          // ruleB should come before ruleA (ruleB has no documentRuleSetId)
          return 1;
        } else if (!ruleAHasDocumentRuleSetId && !ruleBHasDocumentRuleSetId) {
          // Both have no documentRuleSetId, sort by sortOrder
          return ruleA.sortOrder - ruleB.sortOrder;
        } else {
          // Both have documentRuleSetId, first sort by ruleText, then by sortOrder
          const textComparison = ruleA.ruleText.localeCompare(ruleB.ruleText);
          if (textComparison !== 0) {
            return textComparison;
          } else {
            return ruleA.sortOrder - ruleB.sortOrder;
          }
        }
      });

      this.filteredRuleGroups[i].rules = temporaryFilter;
    }

    if (!this.filteredRuleGroups.some(m => m.rules?.length > 0)) {
      this.hidingEverything = true;
    }
  }

  emitDocumentComplianceDataRefresh(rule: RuleResultWithGroups) {
    /** NOTE: we want the name of the document in the toast - in the rule that
     * name could include a list index - we want to remove it
     */
    let docName = '';
    const tmpRule = rule?.rules?.[0] ?? null;
    if (tmpRule) {
      const subcategory = tmpRule.ruleSubCategory ?? '';
      docName = subcategory.replace(` ${tmpRule.ruleSubCategoryId}`, '');
    }

    this.phxSidebarService.onRefreshDocumentComplianceRules.emit({
      complianceDocumentId: +rule.additionalInformation.ComplianceDocumentId,
      filePublicId: rule.additionalInformation.FilePublicId,
      documentName: docName
    });
  }

  /** NOTE: the use of this is temporary to assess the usage of the compliancy 
   * checklist action items - mar 4/24 -  will eventually be removed - story #43109 */
  sendGoogleClickData(clickAction: string) {
    this.googleAnalyticsService.sendClickData({
      feature: 'Compliancy checklist',
      type: this.entityType,
      action: clickAction,
    });
  }

  viewDocument(rule: RuleResultWithGroups): void {
    this.phxSidebarService.entityIdChange$().pipe(
      withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
      take(1)
    ).subscribe(([sidebarEntityId, metaData]) => {

      let entityTypeId = PhxConstants.SideBarEntityTypeId[this.entityType];
      if (this.entityType === PhxConstants.SideBarEntityType.Organization) {
        entityTypeId = rule.additionalInformation.EntityEntityTypeId;
      }

      /** NOTE: document view needs profile id if viewing contact doc */
      let entityId = sidebarEntityId;
      if (metaData?.currentProfileId) {
        entityId = metaData?.currentProfileId;
      }

      this.winRef.nativeWindow.open(
        `#/next/compliance/document-view?id=${+rule.additionalInformation.ComplianceDocumentId}&entitytypeid=${entityTypeId}&entityid=${entityId}&fileId=${rule.additionalInformation.FilePublicId}`,
        '_blank'
      );
    });
  }

  getApiUrl(entityType: string, metaData: any): string {
    /** NOTE: once rule engine is replaced with compliance service - this can go away */
    let endpointToReturn = environment.ruleEngineApiEndpoint;

    const documentComplianceIsActive = metaData?.documentComplianceDataFeatureIsActive ?? false;
    const isDocumentComplianceEntity = entityType === PhxConstants.RuleEngineEntityType.UserProfile || (
      (entityType === PhxConstants.RuleEngineEntityType.WorkOrderLegacy
        || entityType === PhxConstants.RuleEngineEntityType.Organization
      ) && !metaData?.documentComplianceDataExclusionList?.includes(metaData?.organizationClientId)
    );

    if (documentComplianceIsActive && isDocumentComplianceEntity) {
      endpointToReturn = environment.complianceServiceApiEndpoint;
    }

    return endpointToReturn;
  }

  private initHiddenRules() {
    this.hiddenRulesetIds$.subscribe(rulesetIds => {
      this.hiddenRulesetIds = rulesetIds;
    });
  }

  /** NOTE: each entity type has its own rule categories - on change get those categories */
  private initEntityTypeChange() {
    combineLatest([
      this.phxSidebarService.entityTypeChange$(),
      this.phxSidebarService.entityMetaDataChange$()
    ]).pipe(
      filter(([entityType]) => !!entityType),
      switchMap(([entityType, metaData]) => {
        this.entityType = entityType;
        const apiUrl = this.getApiUrl(this.entityType, metaData);
        return this.apiService.httpGetRequest<IRuleCategory[]>(`RuleEngine/GetRuleCategories/${entityType}`, apiUrl);
      }),
      takeUntil(this.isDestroyed$)
    ).subscribe(ruleCategories => {
      this.ruleCategories = ruleCategories;
    });
  }

  /** NOTE: when the entity changes we need to validate its compliancy against the rule server  */
  private initEntityChange() {
    /** NOTE: 2 configurations - run rules each time entity changes or run rules once onload, then user manually runs rules by clicking refresh button */
    if (environment.instantRefreshChecklist === 'true') {
      this.phxSidebarService.entityChange$().pipe(
        filter(entity => Boolean(entity)),
        withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
        switchMap(([changedEntity, entityMetaData]) => this.getCompliancyRuleResults(changedEntity, entityMetaData)),
        takeUntil(this.isDestroyed$)
      ).subscribe();
    } else {
      this.phxSidebarService.entityChange$().pipe(
        filter(entity => Boolean(entity)),
        withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
        switchMap(([changedEntity, entityMetaData]) => this.getCompliancyRuleResults(changedEntity, entityMetaData)),
        take(1)
      ).subscribe();
    }
  }

  /** NOTE: components can emit a refresh using the sidebar service so we need to watch it */
  private initRefreshChecklistEmit() {
    this.phxSidebarService.onRefresh.pipe(
      withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
      switchMap(([, entityMetaData]) => this.getCompliancyRuleResults(this.currentEntityValue, entityMetaData)),
      takeUntil(this.isDestroyed$)
    ).subscribe();
  }

  private initRefreshComplianceDocumentRulesEmit() {
    this.phxSidebarService.onRefreshDocumentComplianceRules.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(complianceData => {
      this.ruleGroups.forEach(group => group.rules = group.rules.filter(rule => {
        if (!rule.additionalInformation) {
          return true;
        }
        if (complianceData.filePublicId) {
          return rule.additionalInformation.FilePublicId !== complianceData.filePublicId;
        }
        return +rule.additionalInformation.ComplianceDocumentId !== complianceData.complianceDocumentId;
      }));
      this.applyFiltersAndSearch();
      this.cdr.detectChanges();
    });
  }

  /** NOTE: filter compliancy checklistlist as the user types a search keyword */
  private initKeyWordChange() {
    this.form.controls.keyword.valueChanges.pipe(
      takeUntil(this.isDestroyed$),
      tap(() => this.applyFiltersAndSearch()),
      debounceTime(1000),
    ).subscribe(searchVal => {
      if (searchVal) {
        this.sendGoogleClickData(`Search: ${searchVal}`);
      }
    });
  }

  /** NOTE: get rule engine compliancy for entity passed in or the current entity */
  private getCompliancyRuleResults(entity: any, entityMetaData: any = null): Observable<IRuleResult[]> {
    this.errorLoadingRules = false;
    const entityToValidate = entity || this.currentEntityValue;
    this.currentEntityValue = entityToValidate;

    const apiUrl = this.getApiUrl(this.entityType, entityMetaData);

    return (entityToValidate ?
      this.apiService.httpPostRequest<IRuleResult[]>(`RuleEngine/RunRuleSet/${this.entityType}/Compliance`, { Entity: entityToValidate }, apiUrl, false).pipe(
        tap(() => {
          this.loadingList = false;
          this.cdr.detectChanges();
        }),
        catchError(() => {
          this.loadingList = false;
          this.errorLoadingRules = true;
          this.cdr.detectChanges();
          return of<IRuleResult[]>([]);
        })
      )
      :
      of<IRuleResult[]>([])).pipe(
        filter(ruleResult => !!ruleResult?.length),
        tap(ruleResult => {
          this.currentEntityValue = entityToValidate;
          this.configureRules(ruleResult);
          this.loadingList = false;
          this.cdr.detectChanges();
        })
      );
  }

  private configureRules(ruleResults: IRuleResult[]) {
    const filteredResults = ruleResults
      .filter(ruleResult => !ruleResult.documentRuleSetId || !this.hiddenRulesetIds.includes(ruleResult.documentRuleSetId));
    
    this.getComplianceRuleOverrides(filteredResults)
    .subscribe(ruleOverrides => {
      const transformedResults = this.applyComplianceRuleOverrides(filteredResults, ruleOverrides);
      this.createRuleGroups(transformedResults);

      /** NOTE: get the current compliance of this entity*/
      this.currentComplianceStatus = Math.max(...this.ruleGroups.map(o => o.groupCompliance));
      this.phxSidebarService.updateEntityStatus(PhxConstants.StatusType.Types?.[this.currentComplianceStatus.valueOf()]);

      this.calculateTotals();
      this.applyFiltersAndSearch();
      this.cdr.detectChanges();
    });
  }

  private getComplianceRuleOverrides(ruleResults: IRuleResult[]) {

    const distinctAdditionalInfos = Array.from(
      new Set(
        ruleResults
          .filter(ruleResult => ruleResult.additionalInformation)
          .map(ruleResult => ruleResult.additionalInformation as string)
      )
    );

    const distinctComplianceDocumentIds = Array.from(
      new Set(
        distinctAdditionalInfos.map(info => {
          const { ComplianceDocumentId } = JSON.parse(info) as { ComplianceDocumentId: string; };
          return ComplianceDocumentId;
        })
      )
    );
    
    const getComplianceRuleOverrides$ = distinctComplianceDocumentIds.length > 0 
      ? this.complianceDataService.getComplianceRuleOverrides('ComplianceDocuments', distinctComplianceDocumentIds) 
      : of<ComplianceRuleOverride[]>([]);

    return getComplianceRuleOverrides$;
  }

  private applyComplianceRuleOverrides(ruleResults: IRuleResult[], ruleOverrides: ComplianceRuleOverride[]): IRuleResult[] {

    const ruleResultComplianceOverridesMap = new Map<IRuleResult, RuleComplianceOverride>();

    const mergedRuleResults = ruleResults.map(ruleResult => {
      const override = this.getMatchedRuleOverride(ruleResult, ruleOverrides);

      if (override) {
        ruleResultComplianceOverridesMap.set(
          ruleResult.additionalInformation,
          convertComplianceRuleOverrideToRuleComplianceOverride(override, ruleResult.isValid)
        );
      }      

      return { 
        ...ruleResult,
        isValid: override?.isValid ?? ruleResult.isValid
      }
    });

    this.ruleResultComplianceOverrides$.next(ruleResultComplianceOverridesMap);

    return mergedRuleResults;
  }

  private getMatchedRuleOverride(ruleResult: IRuleResult, ruleOverrides: ComplianceRuleOverride[]): ComplianceRuleOverride | null {
    if (!ruleResult.additionalInformation) {
      return null;
    }

    const { 
      ComplianceDocumentId,
      FilePublicId,
      RuleId 
    } = JSON.parse(ruleResult.additionalInformation) as { ComplianceDocumentId: string; FilePublicId: string; RuleId: string };
    
    const override = ruleOverrides
      .find(o => o.ruleId === RuleId 
        && o.file.filePublicId === FilePublicId 
        && o.entity.entityId === ComplianceDocumentId
      );

    return override;
  }

  /** NOTE: group the rule result into the categories pulled above in this.initEntityTypeChange */
  private createRuleGroups(ruleResults: IRuleResult[]) {
    const rulesGroupedByCategory = this.groupRulesByProperty(ruleResults, 'ruleCategory');

    let groups: IRuleGroup[] = [];

    Object.keys(rulesGroupedByCategory).forEach(ruleCategory => {
      const rules = rulesGroupedByCategory[ruleCategory] as Array<IRuleResult>;
      if (rules.some(f => f.ruleSubCategory)) {
        groups = [...groups, this.getGroupWithSubGroupRules(rules, ruleCategory)];
      } else {
        groups = [...groups, this.getGroup(rules, ruleCategory)];
      }
    });

    this.ruleGroups = groups.filter(group => !!group);
  }

  private getGroupWithSubGroupRules(rules: IRuleResult[] | RuleResultWithGroups[], categoryId: string): IRuleGroup {
    let category: IRuleCategory | null = null;
    if (categoryId) {
      category = this.ruleCategories.find(f => f.id === categoryId);
    }

    const regularRules: IRuleResult[] = rules.filter(f => !f.ruleSubCategory);
    const rulesWithSubGroup = rules.filter(f => f.ruleSubCategory);
    /** NOTE: update the subcategory name if there are multiples of the same document type
     * RuleSubCategoryId acts as a count of documents of the same type
     */
    const mappedRulesWithSubgroup = rulesWithSubGroup.map(rule => ({
      ...rule,
      ruleSubCategory: `${rule.ruleSubCategory} ${rule.ruleSubCategoryId > 1 ? rule.ruleSubCategoryId : ''}`
    }));
    const rulesGroupedBySubCategory = this.groupRulesByProperty(mappedRulesWithSubgroup, 'ruleSubCategory');

    const nextSortOrder = regularRules[regularRules.length - 1]?.sortOrder ?? 0;
    /** NOTE: subcategory will be the document type name (ie. proof of identity)
     * - multiple documents of the same type have an added count to the name (ie. proof of identity 2, proof of identity 3)
     */
    Object.keys(rulesGroupedBySubCategory).forEach((subCategory, idx) => {
      const subgroupRules = rulesGroupedBySubCategory[subCategory] as Array<IRuleResult>;
      const tmpGroup = this.getGroup(subgroupRules, null);
      tmpGroup.category = subCategory;
      tmpGroup.isOpen = false;
      tmpGroup.documentRuleSetId = subgroupRules?.[0]?.documentRuleSetId;
      /** NOTE: get compliancy counts of the sub-rules in this group that do not have sub-rules */
      const docRuleWarningCount = this.getWarningRules(tmpGroup.rules).length;
      const docRuleNonCompliantCount = this.getNonCompliantRules(tmpGroup.rules).length;
      const ruleStrictness = docRuleNonCompliantCount > docRuleWarningCount ? RuleStrictness.Mandatory : RuleStrictness.Warning;

      let ruleAdditionalInformation: null | { ComplianceDocumentId: number };
      try {
        ruleAdditionalInformation = JSON.parse(subgroupRules.find(f => f.additionalInformation)?.additionalInformation);
      } catch (e) {
        ruleAdditionalInformation = null;
      }

      let parentRuleText = subCategory;
      if (subgroupRules?.[0]?.ruleSubCategoryTranslations) {
        const translatedText = subgroupRules[0].ruleSubCategoryTranslations[this.phxLocalizationService.currentLang];
        if (translatedText) {
          parentRuleText = `${translatedText} ${subgroupRules[0].ruleSubCategoryId > 1 ? subgroupRules[0].ruleSubCategoryId : ''}`;
        }
      }
      /** NOTE: we need to create a rule to be the 'parent' rule for the sub-rules */
      regularRules.push({
        ruleName: subCategory,
        ruleText: parentRuleText,
        strictness: ruleStrictness,
        sortOrder: nextSortOrder + (subgroupRules?.[0]?.sortOrder ?? 0),
        isValid: subgroupRules.every(f => f.isValid),
        additionalInformation: ruleAdditionalInformation,
        actionName: null,
        ruleCategory: subgroupRules?.[0]?.ruleSubCategory,
        ruleSubCategoryTranslations: subgroupRules?.[0]?.ruleSubCategoryTranslations,
        ruleSubCategory: null,
        ruleSubCategoryId: idx,
        ...tmpGroup
      });
    });

    const ruleGroup: IRuleGroup = {
      category: category?.ruleCategoryText,
      length: regularRules?.length,
      rules: regularRules,
      compliantCount: 0,
      warningCount: 0,
      nonCompliantCount: 0,
      groupCompliance: ComplianceStatus.NonCompliant,
      categorySortOrder: category?.sortOrder ?? 0,
      isOpen: true
    };

    this.calculateGroupComplianceAndValidity(ruleGroup);

    return ruleGroup;
  }

  private calculateGroupComplianceAndValidity(ruleGroup: IRuleGroup | IRuleGroup & IRuleResult): void {
    /** NOTE: get compliancy counts of the rules in this group - rules with no sub-rules and the 'parent' rule that has rules */

    ruleGroup.compliantCount = (this.getCompliantRules(ruleGroup.rules)).length;
    ruleGroup.warningCount = (this.getWarningRules(ruleGroup.rules)).length;
    ruleGroup.nonCompliantCount = (this.getNonCompliantRules(ruleGroup.rules)).length;
    ruleGroup.groupCompliance = ruleGroup.nonCompliantCount > 0 ? ComplianceStatus.NonCompliant : (ruleGroup.warningCount > 0 ? ComplianceStatus.Warning : ComplianceStatus.Compliant);

    if (((Object.keys(ruleGroup)) as Array<keyof IRuleResult>).includes('isValid')){
      (ruleGroup as IRuleResult).isValid = ruleGroup.rules.every(rule => rule.isValid);
    }
  }

  private getGroup(rules: Array<IRuleResult>, categoryId: string): IRuleGroup {
    const cleanRules = cleanComplianceRulesResult(rules, 'ruleText', this.phxLocalizationService.currentLang);
    const rulesSorted: IRuleResult[] = cleanRules?.length > 0 ? cleanRules : [];

    const compliantCount = (this.getCompliantRules(rulesSorted)).length;
    const warningCount = (this.getWarningRules(rulesSorted)).length;
    const nonCompliantCount = (this.getNonCompliantRules(rulesSorted)).length;

    const groupCompliance = nonCompliantCount > 0 ? ComplianceStatus.NonCompliant : (warningCount > 0 ? ComplianceStatus.Warning : ComplianceStatus.Compliant);

    let category: IRuleCategory | null = null;
    if (categoryId) {
      category = this.ruleCategories.find(f => f.id === categoryId);
    }

    return {
      category: category?.ruleCategoryText,
      length: rulesSorted?.length,
      rules: rulesSorted,
      compliantCount,
      warningCount,
      nonCompliantCount,
      groupCompliance,
      categorySortOrder: category?.sortOrder || 100,
      isOpen: true
    };
  }

  /** NOTE: group the list of rules by the property passed in */
  private groupRulesByProperty = (ruleResults: IRuleResult[], key: string) => {
    return ruleResults.filter(x => x.strictness !== RuleStrictness.NotApplicable).reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  private getNonCompliantRules(ruleResults: IRuleResult[]) {
    return ruleResults.filter(o => !o.isValid && (o.strictness === RuleStrictness.Mandatory || o.strictness === RuleStrictness.Regular));
  }

  private getWarningRules(ruleResults: IRuleResult[]) {
    return ruleResults.filter(o => !o.isValid && o.strictness === RuleStrictness.Warning);
  }

  private getCompliantRules(ruleResults: IRuleResult[]) {
    return ruleResults.filter(o => o.isValid || o.strictness === RuleStrictness.NotApplicable);
  }

  /** NOTE: calculate total counts for each rule type */
  private calculateTotals() {
    this.warningTotal = 0;
    this.compliantTotal = 0;
    this.nonCompliantTotal = 0;

    this.ruleGroups.forEach(group => {
      this.compliantTotal += group.compliantCount;
      this.nonCompliantTotal += group.nonCompliantCount;
      this.warningTotal += group.warningCount;

      this.calculateSubRuleTotals(group.rules as RuleResultWithGroups[]);
    });

    this.phxSidebarService.checklistRefreshed.emit({ nonCompliantCount: this.nonCompliantTotal });
  }

  private calculateSubRuleTotals(rules: RuleResultWithGroups[]) {
    rules.forEach(rule => {
      if (rule.rules?.length) {
        this.compliantTotal += rule.compliantCount;
        this.nonCompliantTotal += rule.nonCompliantCount;
        this.warningTotal += rule.warningCount;
      }
    });
  }
}

