<div class="rule-info-container">
    
    <table class="info-table">
        <tbody>
            <ng-container *ngIf="rule.operation">
                <tr>
                    <th scope="row">FBO Value</th>
                    <td>{{rule.providedValue.join(', ')}}</td>
                </tr>
                <tr>
                    <th scope="row">Document Value</th>
                    <td>{{rule.extractedValue}}</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="rule.ruleComplianceOverride">
                <tr>
                    <th scope="row">
                        {{ rule.ruleComplianceOverride.validByUser ? 'Approved by' : 'Declined by'}}
                    </th>
                    <td>
                        <div>{{actionedBy?.DisplayText ?? rule.ruleComplianceOverride.actionedByProfileId}}</div>
                        <div class="info-value-caption">
                            {{rule.ruleComplianceOverride.actionedOn | date:dateFormat.mediumDate}} {{rule.ruleComplianceOverride.actionedOn | date:dateFormat.shortTime}}
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <ng-container *ngIf="rule.ruleComplianceOverride">
        <mat-divider></mat-divider>
        <table class="info-table">
            <tbody>
                <tr>
                    <th scope="row">Reason</th>
                    <td>
                        {{reasonCodeDisplayText}}
                    </td>
                </tr>
                <tr>
                    <th scope="row" style="display: none;">Notes</th>
                    <td colspan="2">
                      <ng-container *ngIf="overrideReasonTemplate; else defaultTemplate">
                        <ng-container *ngTemplateOutlet="overrideReasonTemplate"></ng-container>
                      </ng-container>
                      <ng-template #defaultTemplate>
                        {{ rule.ruleComplianceOverride.reasonText }}
                      </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
