import { ComplianceRuleOverride } from '../../data-services/compliance-data/models';
import { RuleComplianceOverride } from '../../rule-compliance/models';

export const convertComplianceRuleOverrideToRuleComplianceOverride = (
  override: ComplianceRuleOverride,
  validBySystem: boolean
): RuleComplianceOverride => {
  return {
    id: override.id,
    validBySystem,
    validByUser: override.isValid,
    isApplicable: validBySystem !== override.isValid,
    actionedByProfileId: override.lastModifiedByProfileGuid,
    actionedOn: override.lastModifiedDateTime,
    reasonCode: override.reasonCode,
    reasonText: override.reasonText
  };
};
