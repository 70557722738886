import { InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AdminDataService } from '../data-services/admin-data/admin-data.service';
import { AuthService } from './auth.service';
import { RuleOverrideReason } from '../rule-compliance/internal-models';

export interface ComplianceRuleOverrideReasons {
  ManualFailReasons: RuleOverrideReason[];
  ManualPassReasons: RuleOverrideReason[];
}

export const COMPLIANCE_RULE_OVERRIDE_REASONS = new InjectionToken<Observable<ComplianceRuleOverrideReasons>>('Compliance Rule Override Reasons');

export const ComplianceRuleOverrideReasonsProvider: Provider = {
  provide: COMPLIANCE_RULE_OVERRIDE_REASONS,
  useFactory: (adminDataService: AdminDataService) => {
    return adminDataService
    .getGlobalConfiguration<ComplianceRuleOverrideReasons>('Configuration', 'GorillaVisionRuleOverrideReasons')
    .pipe(
      map(configuration => mapOverrideReasons(configuration[0]?.Data)),
      shareReplay(1),
    );
  },
  deps: [AdminDataService, AuthService]
};

const mapOverrideReasons = (overrideReasons: ComplianceRuleOverrideReasons): ComplianceRuleOverrideReasons => {
  if (overrideReasons == null) {
    return {
      ManualFailReasons: [],
      ManualPassReasons: [],
    };
  }

  return {
    ManualFailReasons: getSortedReasons(overrideReasons.ManualFailReasons),
    ManualPassReasons: getSortedReasons(overrideReasons.ManualPassReasons),
  }
}

const getSortedReasons = (reasons: RuleOverrideReason[]) => {
  return [...reasons]
    .sort((a, b) => a.DisplayText.localeCompare(b.DisplayText));
}
