import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layouts/layout-component/layout.component';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/account-forgot-password/account-forgot-password.component';
import { RegisterComponent } from './account/register/register.component';
import { ViewEmailInBrowserComponent } from './view-email-in-browser/view-email-in-browser.component';
import { AccountResetPasswordComponent } from './account/app-account-reset-password/app-account-reset-password.component';
import { AccessCodeComponent } from './account/access-code/access-code.component';
import { ServiceUnavailableComponent } from './common/components/service-unavailable/service-unavailable.component';
import { authGuard } from './common/guards/auth.guard';
import { canActivateInternalProfileGuard } from './common/components/guards/can-activate-internal-profile.guard';
import { RedirectMappeduserComponent } from './account/redirect-mappeduser/redirect-mappeduser.component';
import { PortalComponent } from './account/portal/portal.component';
import { MODULE_ROUTES } from './common/constants/module-routes.const';
import { roleAccessChildGuard, roleAccessGuard } from './common/guards/role-access.guard';
import { NoAccessComponent } from './common/components/no-access/no-access.component';
import { PhxConstants } from './common';
import { azsearchGuard } from './common/guards/azsearch.guard';
import { DemoIframeComponent } from './demo/demo-iframe/demo-iframe.component';
import { appResolver } from './common/resolvers/app.resolver';
import { externalAppResolver } from './common/resolvers/external-app.resolver';

const allButOrgWorkerAndBoApprover = PhxConstants.RoleIds.filter(f => !PhxConstants.OrganizationalWorkerBackOfficeApproversRoles.includes(f));

const ROUTES: Routes = [
  {
    path: MODULE_ROUTES.HOME,
    component: HomeComponent,
    resolve: {
      appResolver
    }
  },
  {
    path: MODULE_ROUTES.LOGIN,
    component: LoginComponent,
    resolve: {
      appResolver
    }
  },
  {
    path: MODULE_ROUTES.SSO,
    loadChildren: () => import('./sso/sso.module').then(m => m.SsoModule)
  },
  {
    path: `${MODULE_ROUTES.PORTAL}/:token`,
    component: PortalComponent,
    resolve: {
      appResolver
    }
  },
  {
    path: MODULE_ROUTES.LEGACY_SSO,
    redirectTo: MODULE_ROUTES.SSO
  },
  {
    path: MODULE_ROUTES.ACCESS_TOKEN,
    redirectTo: MODULE_ROUTES.SSO
  },
  {
    path: MODULE_ROUTES.CODE,
    redirectTo: MODULE_ROUTES.SSO
  },
  {
    path: `${MODULE_ROUTES.ACCESS_CODE}/:token`,
    component: AccessCodeComponent,
    resolve: {
      appResolver
    }
  },
  {
    path: `${MODULE_ROUTES.REDIRECT_MAPPED_USER}/:locale/:redirectType/:entityId/:token`,
    component: RedirectMappeduserComponent,
    pathMatch: 'full',
    resolve: {
      externalAppResolver
    }
  },
  {
    path: MODULE_ROUTES.ACCOUNT_FORGOT,
    component: ForgotPasswordComponent,
    resolve: {
      appResolver
    }
  },
  {
    path: MODULE_ROUTES.RESET,
    component: AccountResetPasswordComponent,
    resolve: {
      appResolver
    }
  },
  {
    path: MODULE_ROUTES.REGISTER,
    component: RegisterComponent
  },
  {
    path: MODULE_ROUTES.VIEW_EMAIL_IN_BROWSER,
    component: ViewEmailInBrowserComponent
  },
  {
    path: MODULE_ROUTES.GLOBAL_SEARCH, loadChildren: () => import('./global-search/global-search.module').then(m => m.GlobalSearchModule),
    resolve: {
      appResolver
    },
    canActivate: [authGuard]
  },
  {
    path: MODULE_ROUTES.SERVICE_UNAVAILABLE,
    component: ServiceUnavailableComponent
  },
  {
    path: MODULE_ROUTES.MAIN,
    component: LayoutComponent,
    resolve: {
      appResolver
    },
    canActivate: [authGuard],
    children: [
      {
        path: 'new/:param1',
        component: DemoIframeComponent
      },
      {
        path: MODULE_ROUTES.DEMO, loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule),
        data: { validRoles: [PhxConstants.FunctionalRole.SystemAdministrator] },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.ACCOUNT, loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
      },
      {
        path: MODULE_ROUTES.PAYROLL, loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule),
        data: { validRoles: PhxConstants.FinanceControllerSysAdminRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.COMPLIANCE, loadChildren: () => import('./compliance/compliance.module').then(m => m.ComplianceModule)
      },
      {
        path: MODULE_ROUTES.TIMESHEET, loadChildren: () => import('./time-sheet/time-sheet.module').then(m => m.TimeSheetModule)
      },
      {
        path: MODULE_ROUTES.EXPENSE, loadChildren: () => import('./expense/expense.module').then(m => m.ExpenseModule)
      },
      {
        path: MODULE_ROUTES.ORGANIZATION, loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.ONBOARDING, loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.DOCUMENT_LEGACY, loadChildren: () => import('./document-rule/document-rule.module').then(m => m.DocumentRuleModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivate: [roleAccessGuard]
      },
      {
        path: MODULE_ROUTES.COMMISSION, loadChildren: () => import('./commission/commission.module').then(m => m.CommissionModule)
      },
      { path: MODULE_ROUTES.CONTACT, loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
      {
        path: MODULE_ROUTES.CONTACT_LEGACY, loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        /** TODO: sort these out */
        path: MODULE_ROUTES.PAYMENT, loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: MODULE_ROUTES.JOURNAL, loadChildren: () => import('./journal/journal.module').then(m => m.JournalModule),
        data: { validRoles: PhxConstants.FinanceControllerSysAdminRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.WORK_ORDER_LEGACY, loadChildren: () => import('./workorder/workorder.module').then(m => m.WorkorderModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.WORK_ORDER, loadChildren: () => import('./workorder-2/workorder.module').then(m => m.WorkorderModule2)
      },
      {
        path: MODULE_ROUTES.INVOICE, loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: MODULE_ROUTES.TRANSACTION, loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.PURCHASE_ORDER, loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.ATTRIBUTIONS, loadChildren: () => import('./attributions/attributions.module').then(m => m.AttributionsModule),
        data: { validRoles: allButOrgWorkerAndBoApprover },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.OVERTIME, loadChildren: () => import('./overtime/overtime.module').then(m => m.OvertimeModule),
        data: { validRoles: allButOrgWorkerAndBoApprover },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.PERMANENT_PLACEMENT, loadChildren: () => import('./permanent-placement/permanent-placement.module').then(m => m.PermanentPlacementModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.ACTIVITY_CENTRE, loadChildren: () => import('./activity-centre-2/activity-centre.module')
          .then(m => m.ActivityCentreModule2)
      },
      {
        path: MODULE_ROUTES.REPORT, loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
      },
      {
        path: MODULE_ROUTES.REPORT_SEARCH, loadChildren: () => import('./report-azsearch/report-azsearch.module').then(m => m.ReportAZSearchModule),
        canActivate: [azsearchGuard]
      },
      {
        path: MODULE_ROUTES.USER_GUIDES, loadChildren: () => import('./user-guides/user-guides.module').then(m => m.UserGuidesModule)
      },
      {
        path: MODULE_ROUTES.TEMPLATE, loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
      },
      {
        path: MODULE_ROUTES.SUBSCRIPTION, loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.CONTACT_SUBSCRIPTIONS, loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule)
      },
      {
        path: MODULE_ROUTES.VMS, loadChildren: () => import('./transaction/vms-documents/vms-documents.module').then(m => m.VmsDocumentsModule),
        data: { validRoles: [] }
      },
      {
        path: MODULE_ROUTES.ADMIN, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: { validRoles: [PhxConstants.FunctionalRole.SystemAdministrator] },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.PII, loadChildren: () => import('./pii/pii.module').then(m => m.PiiModule),
        data: { validOperations: [PhxConstants.FunctionalOperation.PIISettings] },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.TEAM, loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
        data: { validRoles: PhxConstants.TeamRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.DOWNLOAD_CENTRE, loadChildren: () => import('./download-center/download-center.module').then(m => m.DownloadCenterModule)
      },
      {
        path: MODULE_ROUTES.STAT_HOLIDAY, loadChildren: () => import('./statholiday/statholiday.module').then(m => m.StatHolidayModule),
        data: { validRoles: [...PhxConstants.FinanceControllerSysAdminRoles, ...PhxConstants.LegalRoles] },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.VMS_DAILY_TIMESHEET,
        canActivate: [canActivateInternalProfileGuard],
        loadChildren: () => import('./vms-daily-timesheet/vms-daily-timesheet.module').then(m => m.VmsDailyTimesheetModule)
      },
      {
        path: MODULE_ROUTES.FOREIGN_EXCHANGE, loadChildren: () => import('./foreign-exchange/foreign-exchange.module').then(m => m.ForeignExchangeModule),
        data: { validRoles: PhxConstants.ForeignExchangeRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      {
        path: MODULE_ROUTES.DOCUMENT, loadChildren: () => import('./document-rule-2/document-rule.module').then(m => m.DocumentRuleModule),
        data: { validRoles: PhxConstants.AllButOrgAndWorkerRoles },
        canActivateChild: [roleAccessChildGuard]
      },
      { path: MODULE_ROUTES.NO_ACCESS, component: NoAccessComponent },
      {
        path: MODULE_ROUTES.MAIL_CAMPAIGN, loadChildren: () => import('./mail-campaign/mail-campaign.module').then(m => m.MailCampaignModule),
      },
    ]
  },
  {
    path: '**',
    redirectTo: MODULE_ROUTES.HOME,
  }
];

export const AppRouting = RouterModule.forRoot(ROUTES, { useHash: true });
