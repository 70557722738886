import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhxPanelBarComponent } from './components/phx-panel-bar/phx-panel-bar.component';
import { PhxPanelChecklistComponent } from './components/phx-panel-checklist/phx-panel-checklist.component';
import { PhxPanelCustomComponent } from './components/phx-panel-custom/phx-panel-custom.component';
import { PhxPanelNotesComponent } from './components/phx-panel-notes/phx-panel-notes.component';
import { PhxPanelVersionComponent } from './components/phx-panel-version/phx-panel-version.component';
import { PhoenixCommonModule } from '../PhoenixCommon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RuleComplianceModule } from '../rule-compliance/rule-compliance.module';
import { PanelChecklistRuleComponent } from './components/panel-checklist-rule/panel-checklist-rule.component';


@NgModule({
  declarations: [
    PhxPanelBarComponent,
    PhxPanelChecklistComponent,
    PhxPanelCustomComponent,
    PhxPanelNotesComponent,
    PhxPanelVersionComponent,
    PanelChecklistRuleComponent
  ],
  imports: [
    CommonModule,
    PhoenixCommonModule,
    ReactiveFormsModule,
    MatIconModule,
    RuleComplianceModule
  ],
  exports: [
    PhxPanelBarComponent,
    PhxPanelCustomComponent
  ]
})
export class PanelBarModule { }
