/**
 * Tooltip opens when mouse over target.
 * It also stays open when mouse over tooltip itself.
 *
 * Tooltip closes when mouse leave target or tooltip.
 */

import { Component, Input, ViewChild } from '@angular/core';
import { DxTooltipComponent } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';

@Component({
  selector: 'app-phx-tool-tip',
  templateUrl: './phx-tooltip.component.html'
})
export class PhxToolTipComponent {
  @ViewChild(DxTooltipComponent, { static: true })
  dxTooltipComponent: DxTooltipComponent;

  @Input() targetId: string = null;
  @Input() position: DevExpress.common.Position = 'top';
  @Input() visible: boolean = false;
  @Input() showAnimation: boolean = false;
  @Input() showAnimationType: string = 'slide'; // 'slide' | 'pop'
  @Input() hideAnimationType: string = 'pop'; // 'slide' | 'pop'
  @Input() showEvent = { delay: 300, name: 'dxclick dxhoverstart' };
  @Input() hideEvent: {
    delay: number;
    name: string;
  } | null = {
    delay: 500,
    name: 'dxhoverend'
  };

  private isMouseOverTooltip = false;

  public close(): void {
    this.mouseLeaveTooltip();
  }

  public mouseOverTooltip(): void {
    this.isMouseOverTooltip = true;
    void this.dxTooltipComponent.instance.show();
  }

  public mouseLeaveTooltip(): void {
    this.isMouseOverTooltip = false;
    if (this.hideEvent?.name === 'dxhoverend') {
      void this.dxTooltipComponent.instance.hide();
    }
  }

  /**
   * A function that is executed before the widget is hidden.
   * https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxTooltip/Configuration/#onHiding
   * @param obj
   *    component: Tooltip -- The widget's instance.
   *    element: Element (jQuery or HTML) -- The widget's container. It is an HTML Element or a jQuery Element when you use jQuery.
   *    model: Object -- The model data. Available only if Knockout is used.
   *    cancel: Boolean -- Allows you to cancel overlay hiding
   */
  public onHiding(obj: { component: any; element: any; model: any; cancel: any }): void {
    if (this.isMouseOverTooltip) {
      obj.cancel = true;
    }
  }
}
