import { InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AdminDataService } from '../data-services/admin-data/admin-data.service';
import { AuthService } from './auth.service';

export const GV_HIDDEN_RULESET_IDS = new InjectionToken<Observable<boolean>>('GV Hidden Ruleset Ids');

interface GorillaVisionHiddenRules {
  HiddenRulesetIds: string[];
  VisibleToUserProfileIds: number[];
}

export const GVHiddenRulesetIdsProvider: Provider = {
  provide: GV_HIDDEN_RULESET_IDS,
  useFactory: (adminDataService: AdminDataService, authService: AuthService) => {
    return adminDataService.getGlobalConfiguration<GorillaVisionHiddenRules>('Configuration', 'GorillaVisionHiddenRules')
    .pipe(
      map(response => getHiddenRulesetIds(response[0]?.Data, authService.currentProfile.Id)),
      shareReplay(1),
    );
  },
  deps: [AdminDataService, AuthService]
};

const getHiddenRulesetIds = (configuration: GorillaVisionHiddenRules, currentProfileId: number) => {
  // No hidden rulesets or profile can see hidden rulesets
  if (!configuration?.HiddenRulesetIds?.length || configuration.VisibleToUserProfileIds.includes(currentProfileId)) {
    return [];
  }

  return configuration.HiddenRulesetIds;
};
