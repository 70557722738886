import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AppInitService } from '../../app-init.service';
import { PhxConstants } from '../model';
import { MODULE_ROUTES } from '../constants/module-routes.const';

const skipFailRedirectPaths = new Set<string>([
  MODULE_ROUTES.HOME,
  MODULE_ROUTES.LOGIN,
  MODULE_ROUTES.ACCOUNT_FORGOT,
  MODULE_ROUTES.SSO,
  MODULE_ROUTES.RESET,
  `${MODULE_ROUTES.ACCESS_CODE}/:token`,
  `${MODULE_ROUTES.PORTAL}/:token`
]);

const automaticRedirectIfLoggedInPaths = new Set<string>([MODULE_ROUTES.HOME, MODULE_ROUTES.LOGIN]);

export const createAppResolverFactory = (isExternal?: boolean): ResolveFn<void> => {
  return (route: ActivatedRouteSnapshot): Observable<void> => {
    const authService = inject(AuthService);
    const appInitService = inject(AppInitService);
    const router = inject(Router);
    const redirectIfLoggedIn = () => {
      if (!authService.isLoggedIn()) {
        appInitService.loaded = false;
        return;
      }
      if (route?.routeConfig && automaticRedirectIfLoggedInPaths.has(route.routeConfig.path)) {
        authService.redirectAfterLogin();
      }
    };

    return new Observable(observer => {
      if (appInitService.loaded) {
        observer.next();
        observer.complete();
        redirectIfLoggedIn();
      } else {
        appInitService
          .initApp(isExternal ? route.params : undefined)
          .then(() => {
            observer.next();
            observer.complete();
            redirectIfLoggedIn();
          })
          .catch(err => {
            if (err?.status >= PhxConstants.HTTPResponseStatus.InternalServerError) {
              observer.next();
              observer.complete();
              void router.navigateByUrl(`/${MODULE_ROUTES.SERVICE_UNAVAILABLE}`, { skipLocationChange: true });
              return;
            }

            authService.loadTranslationDataByBrowserCulture().subscribe(res => {
              (window as any).PhxTranslations = res;
              if (route?.routeConfig && !skipFailRedirectPaths.has(route.routeConfig.path)) {
                void router.navigateByUrl('/');
              }
              observer.next();
              observer.complete();
            });
          });
      }
    });
  };
};
