import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-rule-compliance-status-icon',
  templateUrl: './rule-compliance-status-icon.component.html',
  styleUrls: ['./rule-compliance-status-icon.component.less']
})
export class RuleComplianceStatusIconComponent implements OnChanges {
  @Input() valid: boolean;
  @Input() overriden?: boolean;
  @Input() ruleLevel: string;

  /**
   * If true, show/hide the override icon. Spacing for this icon is preserved even if rule is not overriden
   */
  @Input() showOverride: boolean = true;

  public class: string;
  public icon: string;
  public text: string;

  private warningStatusList = new Set(['Warning', 'Info']);

  ngOnChanges() {
    const classes = [];

    if (this.valid) {
      classes.push('ck-success');
      this.icon = 'check_circle';
      this.text = 'Passed';
    }
    else if (this.warningStatusList.has(this.ruleLevel)) {
      classes.push('ck-warn');
      this.icon = 'report_problem';
      this.text = 'Warning';
    }
    else {
      classes.push('ck-critical');
      this.icon = 'cancel';
      this.text = 'Failed';
    }

    this.class = classes.join(' ');
  }
}
