<div class="ck-rule">
    <app-rule-compliance-status-icon
        [valid]="rule.isValid"
        [ruleLevel]="ruleStrictness[rule.strictness]"
        [overriden]="ruleComplianceOverride?.isApplicable"
        [showOverride]="rule.documentRuleSetId && !ruleGroup"
    >
    </app-rule-compliance-status-icon>

    <div
        class="ck-text"
        [class.ck-text-disabled]="rule.strictness === ruleStrictness.NotApplicable"
    >
        <span 
            [class.ck-heading]="styleAsHeading"
        >
            {{ruleGroup?.category ?? rule.ruleText}}
        </span>
        <span 
            *ngIf="ruleGroup"
        >
            ({{ruleGroup.compliantCount}}/{{ruleGroup.length}})</span>

        <a *ngIf="rule.operation || ruleComplianceOverride?.isApplicable"
            [id]="tooltipId"
            class="rule-tooltip"
        >
            details
        </a>
        <app-phx-tool-tip 
            *ngIf="rule.operation || ruleComplianceOverride?.isApplicable"
            [targetId]="'#' + tooltipId"
            position="bottom"
            [showEvent]="{ delay: 0 ,name: 'dxclick' }"
            [hideEvent]="null"
        >
          <app-rule-compliance-info-table
            [rule]="{
            extractedValue: rule.extractedValue,
            operation: rule.operation,
            providedValue: rule.providedValue,
            ruleComplianceOverride: ruleComplianceOverride
            }"
            [overrideReasonTemplate]="overrideReasonTemplate"
          ></app-rule-compliance-info-table>
        </app-phx-tool-tip>
    </div>

</div>


<ng-template #overrideReasonTemplate>
  <div class="override-reason">
    {{ ruleComplianceOverride.reasonText }}
  </div>
</ng-template>
