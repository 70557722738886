import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { IPanelItem } from 'src/app/common/model/panel-bar';
import { PhxConstants } from 'src/app/common/model';
import { PhxSideBarService } from 'src/app/common/services/phx-side-bar.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { GoogleAnalyticsService } from 'src/app/common/services/google-analytics/google-analytics.service';
import { ClickAnalyticsData } from 'src/app/common/services/google-analytics/models/click-analytics-data.model';
import { IframeService } from 'src/app/common/services/iframe.service';

@Component({
  selector: 'app-phx-panel-bar',
  templateUrl: './phx-panel-bar.component.html',
  styleUrls: ['./phx-panel-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideRightToggle', [
      transition(':enter', [style({ transform: 'translateX(100%)' }), animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))])
    ])
  ]
})
export class PhxPanelBarComponent extends BaseComponentOnDestroy implements AfterViewInit, OnInit, OnDestroy {
  @Input() isSidebarOpen: boolean;
  @Output() toggle = new EventEmitter<boolean>();

  panelList: IPanelItem[];
  selectedPanel: IPanelItem;
  phxConstants: typeof PhxConstants = PhxConstants;
  hasExpandButton = true;
  inIframe = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private phxSidebarService: PhxSideBarService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private iFrameService: IframeService
  ) {
    super();
    this.inIframe = iFrameService.isIframe;
  }

  ngOnInit() {
    this.subscribeToActivePanelChange();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.subscribeToPanelsChanges();
  }

  clickPanel(panel: IPanelItem): void {
    if (panel.action) {
      panel.action();
    } else {
      this.togglePanel(panel);
    }
  }

  toggleVisibility() {
    this.toggle.emit();
    this.logGoogleAnalyticsEvent({
      feature: 'Sidebar',
      type: this.selectedPanel?.name,
      action: this.isSidebarOpen ? 'Opening' : 'Closing'
    });
  }

  private togglePanel(panel: IPanelItem): void {
    /** NOTE: if same panel is open then close panel */
    if (this.selectedPanel?.type === panel?.type) {
      this.toggleVisibility();
    } else {
      /** NOTE: set new selected panel - if not open - open it */
      this.selectedPanel = panel;
      this.phxSidebarService.setActivePanel(panel.type);
      if (!this.isSidebarOpen) {
        this.toggleVisibility();
      } else {
        this.logGoogleAnalyticsEvent({
          feature: 'Sidebar',
          type: this.selectedPanel?.name,
          action: 'Switching to'
        });
      }
    }
    this.cdr.detectChanges();
  }

  private logGoogleAnalyticsEvent<T extends ClickAnalyticsData>(event: T): void {
    this.googleAnalyticsService.sendClickData(event);
  }

  private subscribeToPanelsChanges(): void {
    this.phxSidebarService
      .panelListChange$()
      .pipe(withLatestFrom(this.phxSidebarService.openOnLoadPanelType$()), takeUntil(this.isDestroyed$))
      .subscribe(([panelList, openOnLoadPanelType]) => {
        this.panelList = panelList;
        this.hasExpandButton = this.panelList?.some(p => !p.action);

        if (openOnLoadPanelType) {
          this.selectedPanel = this.panelList.find(f => f.type === openOnLoadPanelType) ?? this.panelList?.[0];
          this.phxSidebarService.openSidebar();
        } else {
          this.selectedPanel = this.panelList?.[0];
        }

        this.cdr.detectChanges();
      });
  }

  private subscribeToActivePanelChange(): void {
    this.phxSidebarService.activePanel$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(p => !!p)
      )
      .subscribe(panel => {
        this.selectedPanel = panel;
        this.cdr.detectChanges();
      });
  }
}
