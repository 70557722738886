import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoenixCommonModule } from '../PhoenixCommon.module';
import { RuleComplianceStatusIconComponent } from './components/rule-compliance-status-icon/rule-compliance-status-icon.component';
import { RuleComplianceResultsTableComponent } from './components/rule-compliance-results-table/rule-compliance-results-table.component';
import { RuleComplianceOverrideModalComponent } from './components/rule-compliance-override-modal/rule-compliance-override-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { RuleComplianceInfoTableComponent } from './components/rule-compliance-info-table/rule-compliance-info-table.component';
import { MatDividerModule } from '@angular/material/divider';
import { ComplianceRuleOverrideReasonsProvider } from '../services/compliance-rule-override-reasons.provider';



@NgModule({
  providers: [ComplianceRuleOverrideReasonsProvider],
  declarations: [
    RuleComplianceResultsTableComponent,
    RuleComplianceStatusIconComponent,
    RuleComplianceOverrideModalComponent,
    RuleComplianceInfoTableComponent
  ],
  imports: [
    CommonModule,
    PhoenixCommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    CdkAccordionModule,
    MatDividerModule
  ],
  exports: [
    RuleComplianceResultsTableComponent,
    RuleComplianceStatusIconComponent,
    RuleComplianceInfoTableComponent
  ]
})
export class RuleComplianceModule { }
