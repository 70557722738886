<div class="ck-panel">
    <div class="ck-panel-body" *ngIf="!loadingList && !errorLoadingRules">
        <!-- NOTE: top status header -->
        <div class="status-container" [ngSwitch]="currentComplianceStatus">
            <ng-container *ngSwitchCase="ComplianceStatus.Compliant">
                <h3 class="ck-success">
                    <span class="material-icons-outlined">
                        check_circle
                    </span>
                    <span i18n="@@phx.panelChecklist.compliant">Compliant</span>
                </h3>
            </ng-container>

            <ng-container *ngSwitchCase="ComplianceStatus.Warning">
                <h3 class="ck-warn">
                    <span class="material-icons-outlined">
                        warning
                    </span>
                    <span i18n="@@phx.panelChecklist.compliantWithWarning">Warning</span>
                </h3>
            </ng-container>

            <ng-container *ngSwitchCase="ComplianceStatus.NonCompliant">
                <h3 class="ck-critical">
                    <span class="material-icons-outlined">
                        cancel
                    </span>
                    <span i18n="@@phx.panelChecklist.nonCompliant">Non Compliant</span>
                </h3>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <h3 class="ck-none">
                    <span class="material-icons-outlined">
                        check_circle
                    </span>
                    <span i18n="@@phx.panelChecklist.compliancyRules">Compliancy Rules</span>
                </h3>
            </ng-container>
        </div>
        <!-- NOTE: search box and filter button -->
        <div class="search-box inline">
            <div class="search-input" [formGroup]="form">
                <phx-form-control [control]="form.get('keyword')" [editable]="true" [layoutType]="inputOnlyLayoutType">
                    <div class="input-group">
                        <input name="keyword" [formControl]="form.get('keyword')" type="text"
                            class="form-control border-radius-ltlb-4" placeholder="Search"
                            i18n-placeholder="@@common.search" />
                        <span class="input-group-addon border-radius-rtrb-4" style="padding:3px 6px;">
                            <i class="material-icons-outlined">search</i>
                        </span>
                    </div>
                </phx-form-control>
            </div>

            <div class='ck-expands'>
                <div class="btn-group">
                    <div class="btn-wrapper">
                        <button type="button"
                            class="btn btn-default btn-material margin-10-l button-34-34 filter-button dropdown-toggle "
                            [ngClass]="{
                                'has-filter': hidingCompliantRules || hidingWarningRules || hidingNoncompliantRules,
                                'is-success': currentComplianceStatus === ComplianceStatus.Compliant, 
                                'is-warning': currentComplianceStatus === ComplianceStatus.Warning, 
                                'is-critical': currentComplianceStatus === ComplianceStatus.NonCompliant
                            }" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i class="material-icons">filter_alt</i>
                        </button>
                        <div class="filter-dropdown-menu dropdown-menu bordered dropdown-menu-right"
                            *ngIf="compliantTotal || warningTotal || nonCompliantTotal">
                            <ul>
                                <li class="action-link" [ngClass]="{'disabled':hidingCompliantRules}"
                                    (click)='filterStatus[0] = !filterStatus[0]; applyFiltersAndSearch()'
                                    *ngIf="compliantTotal">
                                    <span *ngIf="hidingCompliantRules"
                                        i18n="@@phx.panelChecklist.compliantHidden">Compliant rules hidden
                                        ({{compliantTotal}})</span>
                                    <span *ngIf="!hidingCompliantRules" i18n="@@phx.panelChecklist.hideCompliant">Hide
                                        compliant rules ({{compliantTotal}})</span>
                                </li>
                                <li class="action-link" [ngClass]="{'disabled':hidingWarningRules}"
                                    (click)='filterStatus[2] = !filterStatus[2]; applyFiltersAndSearch()'
                                    *ngIf="warningTotal">
                                    <span *ngIf="hidingWarningRules" i18n="@@phx.panelChecklist.warningHidden">Rules
                                        with warning hidden ({{warningTotal}})</span>
                                    <span *ngIf="!hidingWarningRules" i18n="@@phx.panelChecklist.hideWarning">Hide rules
                                        with warning ({{warningTotal}})</span>
                                </li>
                                <li class="action-link" [ngClass]="{'disabled':hidingNoncompliantRules}"
                                    (click)='filterStatus[1] = !filterStatus[1]; applyFiltersAndSearch()'
                                    *ngIf="nonCompliantTotal">
                                    <span *ngIf="hidingNoncompliantRules"
                                        i18n="@@phx.panelChecklist.nonCompliantHidden">Non-compliant rules hidden
                                        ({{nonCompliantTotal}})</span>
                                    <span *ngIf="!hidingNoncompliantRules"
                                        i18n="@@phx.panelChecklist.hideNonCompliant">Hide
                                        non-compliant rules ({{nonCompliantTotal}})</span>
                                </li>
                                <li class="action-link reset-link"
                                    *ngIf="hidingWarningRules || hidingNoncompliantRules || hidingCompliantRules"
                                    (click)='resetFilters()'>
                                    <span i18n="@@common.button.reset" class="btn btn-default">Reset</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ck-accord">
            <!-- NOTE: we hide non applicable rules - Default text when no rules are shown Story #42386 -->
            <div class="ck-accord-body rule-group-container" *ngIf="!filteredRuleGroups?.length">
                <div class="ck-rule padding-10-h">
                    <mat-icon fontSet="material-icons-outlined">
                        do_not_disturb_on
                    </mat-icon>
                    <div class="ck-text-disabled">
                        <p class="ck-text" i18n="@@phx.panelChecklist.noRulesApplicable">
                            No rules are applicable
                        </p>
                    </div>
                </div>
            </div>
            <!-- NOTE: looping through groups of rules. e.g. Internal, Client, or Payment rules -->
            <ng-container *ngIf="!hidingEverything">
                <div *ngFor="let group of filteredRuleGroups; trackBy: trackByFn"
                    [ngClass]="{'rule-group-container': group.rules?.length}">
                    <div *ngIf="!!group.rules?.length">
                        <app-phx-accordion [closeOthers]="false">
                            <app-phx-accordion-group #chk [(isOpen)]="group.isOpen" [showToggle]="false" class="group-accordion">
                                <div class="ck-accord-head" accordion-heading
                                    (click)="sendGoogleClickData('Collapse ' + group.category + ' group');">
                                    <ng-container
                                        *ngTemplateOutlet="groupComplianceIcon; context:{ $implicit: group.groupCompliance }"></ng-container>
                                    <span class="ck-title">
                                        {{ group.category}} <span class="ck-count">({{group.compliantCount}}/{{group.length}})</span>
                                    </span>

                                    <mat-icon>
                                        {{ chk.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                        <span class="cdk-visually-hidden">
                                            {{ chk.isOpen ? 'Collapse' : 'Expand'}}
                                        </span>
                                    </mat-icon>
                                </div>

                                <!-- NOTE: looping through rules within a group -->
                                <!-- Non-applicable rules do not show up anymore Story #42386 -->
                                <div class="ck-accord-body">
                                    <div *ngFor="let rule of group.rules; trackBy: trackByFn; let groupIndex = index">
                                        <ng-container *ngIf="!rule.rules?.length">
                                            <app-panel-checklist-rule [rule]="rule"></app-panel-checklist-rule>
                                        </ng-container>
                                        <ng-container *ngIf="rule.rules?.length">
                                            <div [ngClass]="{'subrule-accordian-container ': rule.isOpen}"
                                                class="document-rule-container">
                                                <app-phx-accordion [closeOthers]="true">
                                                    <app-phx-accordion-group #subrule [(isOpen)]="rule.isOpen"
                                                        [showToggle]="false"
                                                        panelClass="{{rule.isOpen ? 'compliance-doc-accordian-group' : 'closed-compliance-doc-accordian-group'}}">
                                                      <div accordion-heading class="ck-rule document-rule"
                                                           [ngClass]="{'has-subrules': rule.isOpen}">
                                                        <app-panel-checklist-rule
                                                          [rule]="rule"
                                                          [ruleGroup]="rule"
                                                          [styleAsHeading]="rule.isOpen"
                                                        >
                                                        </app-panel-checklist-rule>
                                                        <mat-icon>
                                                          {{ subrule.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                                          <span class="cdk-visually-hidden">
                                                                    {{ subrule.isOpen ? 'Collapse' : 'Expand'}}
                                                                </span>
                                                        </mat-icon>
                                                      </div>
                                                        <div accordion-heading-buttons class="document-more-icon" [ngClass]="{'is-open': rule.isOpen}"
                                                             *ngIf="rule.additionalInformation.ComplianceDocumentId">
                                                          <div class="btn-group">
                                                            <div class="btn-wrapper">
                                                              <div class="dropdown-toggle " data-toggle="dropdown"
                                                                   aria-haspopup="true" aria-expanded="true">
                                                                <mat-icon>more_vert</mat-icon>
                                                              </div>
                                                              <div
                                                                class="document-dropdown-menu  dropdown-menu bordered dropdown-menu-right">
                                                                <ul>
                                                                    <li>
                                                                        <a (click)="viewDocument(rule)">View document</a>
                                                                    </li>
                                                                    <li>
                                                                        <a (click)="emitDocumentComplianceDataRefresh(rule)">Re-analyze document</a>
                                                                    </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div
                                                          *ngFor="let ruleRule of rule.rules; trackBy: trackByFn; let idx = index"
                                                          class="ck-sub-rule"
                                                        >
                                                            <app-panel-checklist-rule
                                                              [rule]="ruleRule"
                                                              [ruleComplianceOverride]="(ruleResultComplianceOverrides$ | async).get(ruleRule.additionalInformation)"
                                                            ></app-panel-checklist-rule>
                                                        </div>
                                                    </app-phx-accordion-group>
                                                </app-phx-accordion>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </app-phx-accordion-group>
                        </app-phx-accordion>
                    </div>
                </div>
            </ng-container>
            <!-- NOTE: messaging when filter hides all rules -->
            <div class="everything-hidden-message rule-group-container"
                *ngIf="hidingEverything && filteredRuleGroups?.length" i18n="@@phx.panelChecklist.noRulesToDisplay">
                No rules to display. Please change the filter settings.
            </div>
        </div>
    </div>
    <!-- NOTE: error mesage when ruels cannot load -->
    <div class="status-container" *ngIf="errorLoadingRules">
        <h3 class="ck-critical">
            <span class="material-icons-outlined">
                cancel
            </span>
            <span i18n="@@phx.panelChecklist.unableToLoadRules" class="error-status">Sorry, unable to load compliancy
                rules.</span>
        </h3>
    </div>
    <!-- NOTE: loader -->
    <div class="loader-container" *ngIf="loadingList">
        <app-bouncing-balls [color]="loadingColor"></app-bouncing-balls>
    </div>
</div>

<!-- NOTE: template for group title icons -->
<ng-template #groupComplianceIcon let-groupCompliance>
    <app-rule-compliance-status-icon
        [valid]="groupCompliance === ComplianceStatus.Compliant"
        [ruleLevel]="ComplianceStatus[groupCompliance]"
        [showOverride]="false"
    >
    </app-rule-compliance-status-icon>
</ng-template>
